.facebook-logo {
	height: 48px;
	width: 48px;
	left: 0;
	position: relative;
	left: 0px;
}

#wallpaper-dashboard {
	width: 100%;
	position: relative;
	top: 0;
	z-index: -1;
	background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #230028 100%);
	mask-image: linear-gradient(rgba(0, 0, 0, 1) 60%, transparent);
}
