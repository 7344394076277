$color_1: white;
$color_2: var(--white);
$color_3: var(--black);
$color_4: #ffffff;
$color_5: #0d6efd;
$font-family_1: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
$font-family_3: "Sora";
$background-color_1: rgb(48, 101, 208);
$background-color_2: transparent;
$background-color_3: white;
$background-color_4: var(--white);

@import "./Variables.scss";

.facebook-access {
	width: 100%;
	margin-top: 10px;
	color: $color_1;
	background-color: $background-color_1;
	width: 240px;
	height: 50px;
	border: none;
	text-align: center;
	font-family: $font-family_1;
	box-shadow: rgb(0 0 0 / 30%) 0px 0px 3px 3px;
	font-size: 16px;
	line-height: 48px;
	border-radius: 1px;
	transition: background-color 0.218s ease 0s, border-color 0.218s ease 0s, box-shadow 0.218s ease 0s;
	cursor: pointer;
	user-select: none;
}

.btn-outline {
	font-family: $font-family_3;
	display: block;
	border-radius: 25px;
	border: 1px solid var(--white);
	color: $color_2;
	background-color: $background-color_2;
	padding: 8px 10px;
	transition: background-color 0.2s, color 0.2s;

	&:hover {
		background-color: $background-color_4;
		color: $color_3;
	}
}

.btn-outline-wallpaper {
	width: 275.95px;
	padding: 14px;
	height: 58.2px;
	text-align: center;
	border-radius: 100px;
	border: 1px solid var(--white);
	color: $color_3;
	gap: 10px;
	background-color: $background-color_3;
	transition: background-color 0.2s, color 0.2s;
}

.SingUpGoogleBtn {
	font-family: $font-family_3 !important;
	display: block !important;
	border-radius: 25px !important;
	border: 1px solid var(--white) !important;
	color: $color_2 !important;
	background-color: $background-color_2 !important;
	padding: 8px 10px !important;
	transition: background-color 0.2s, color 0.2s !important;
	text-decoration: none !important;
	text-align: center !important;
	font-size: 16px !important;
	width: auto !important;
	margin: 0px !important;
	margin-left: -10px !important;
	margin-right: 20px !important;

	&:hover {
		color: $color_2 !important;
		background: var(--gradient-01) !important;
		border: 1px solid var(--background) !important;
		cursor: pointer !important;
	}

	div {
		height: 0 !important;
	}
}

.btn-fill {
	font-family: $font-family_3;
	display: block;
	border-radius: 25px;
	border: 1px solid var(--white);
	color: $color_2;
	background-color: $background-color_2;
	padding: 8px 10px;

	&:hover {
		background: var(--gradient-01);
		color: $color_2;
		cursor: pointer;
	}
}

.btn-no-fill {
	font-family: $font-family_3;
	display: block;
	border-radius: 25px;
	border: 1px solid var(--white);
	color: $color_3;
	background-color: $background-color_3;
	padding: 8px 10px;

	&:hover {
		background-color: $background-color_2;
		color: $color_2;
		cursor: pointer;
	}
}

.btn-no-fill-no-hover {
	font-family: $font-family_3;
	display: block;
	border-radius: 25px;
	border: 1px solid var(--white);
	color: $color_3;
	background-color: $background-color_3;
	padding: 8px 10px;
}

.a-fill {
	font-family: $font-family_3;
	font-style: normal;
	display: block;
	border-radius: 25px;
	border: 1px solid var(--white);
	color: $color_2;
	background-color: $background-color_2;
	padding: 8px 10px;

	&:hover {
		background: var(--gradient-01);
		color: $color_2;
		cursor: pointer;
	}
}

.a-fill-no-hover {
	font-family: $font-family_3;
	font-style: normal;
	display: block;
	border-radius: 25px;
	border: 1px solid var(--white);
	color: $color_2;
	background-color: $background-color_2;
	padding: 8px 10px;

	&:hover {
		color: $color_2;
	}
}

.link-header {
	color: $color_4;
	box-sizing: border-box;
	padding: 7px 20px;
	outline: none;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	height: fit-content;
	text-decoration: none;

	&:hover {
		color: $color_5;
	}
}

.loading-button {
	width: 100%;
	display: flex;
	justify-content: center;

	svg {
		margin-top: auto;
		margin-bottom: auto;
	}
}


.storm-button-variant-1 {
	border-radius: 100px;
}

.btn-gems-pack {
	border-radius: 10px;
	background-color: var(--purple-02);
	color: var(--white);
	font-family: $font-family_3;
	display: block;
	padding: 20px;
	width: 100%;
	border: none;
	outline: none;
	transition: background 0.2s ease-in-out;
}

@keyframes galaxy {
	0% {
		background-position: 50% 50%;
	}

	100% {
		background-position: 50% 50%;
	}

	/* Aggiungo regole per ogni percentuale dal 1% al 99% */
	@for $i from 1 through 99 {
		#{$i}% {
			background-position: calc(50% + #{random()} * 50px) calc(50% + #{random()} * 50px);
		}
	}
}

.gsi-material-button {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	-webkit-appearance: none;
	background-color: #f2f2f2;
	background-image: none;
	border: none;
	-webkit-border-radius: 20px;
	border-radius: 20px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	color: #1f1f1f;
	cursor: pointer;
	font-family: 'Roboto', arial, sans-serif;
	font-size: 14px;
	height: 40px;
	letter-spacing: 0.25px;
	outline: none;
	overflow: hidden;
	padding: 0 12px;
	position: relative;
	text-align: center;
	-webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
	transition: background-color .218s, border-color .218s, box-shadow .218s;
	vertical-align: middle;
	white-space: nowrap;
	width: 100%;
	max-width: 400px;
	min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
	height: 20px;
	margin-right: 12px;
	min-width: 20px;
	width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
	-webkit-align-items: center;
	align-items: center;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: nowrap;
	flex-wrap: nowrap;
	height: 100%;
	justify-content: space-between;
	position: relative;
	width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
	-webkit-flex-grow: 1;
	flex-grow: 1;
	font-family: 'Roboto', arial, sans-serif;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
	-webkit-transition: opacity .218s;
	transition: opacity .218s;
	bottom: 0;
	left: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.gsi-material-button:disabled {
	cursor: default;
	background-color: #ffffff61;
}

.gsi-material-button:disabled .gsi-material-button-state {
	background-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
	opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
	opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
	background-color: #001d35;
	opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
	-webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
	box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
	background-color: #001d35;
	opacity: 8%;
}