.why-us-image {
	transition: all 1s;

	width: 568px;
	transform: rotateY(-25deg) rotateX(10deg) translateZ(+100px); /* Adjust the rotation and translation values to control the depth and angle */
	&:hover {
		transition: all 1s;
		transform: rotateY(calc(-25deg + var(--mouse-x) / 80)) rotateX(calc(10deg - var(--mouse-y) / 40)) translateZ(+100px);
	}
	@media (max-width: 1500px) {
		width: 400px;
	}

	@media (max-width: 1200px) {
		width: 100%;
		max-width: 400px;
		margin-left: 40px;
	}

	@media (max-width: 850px) {
		margin-left: 120px;

		width: 110%;
	}
	@media (max-width: 700px) {
		margin-top: 50px;
		max-width: unset;
		margin-left: unset;

		position: relative;
		right: -15%;
		width: 100%;
	}
}
.container-why-us-call-to-action {
	margin-top: 36px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 24px;
	position: relative;

	@media (max-width: 700px) {
		gap: 12px;
		flex-direction: column;
	}
	@media (min-width: 700px) {
		> *:last-child {
			display: flex;
			overflow: hidden;
			max-width: 100%;
			flex-wrap: nowrap;

			position: relative;
			opacity: 0;
			transition: opacity 1s ease-out;
			align-self: flex-start;
		}
		&.accordion-text-open {
			flex-direction: column;
			align-items: flex-start;

			> *:last-child {
				max-width: 0 !important;
				opacity: 0 !important;
				transition: opacity 1s ease-out;
			}
		}
	}
}
#why-us-text-container {
	opacity: 0;
	z-index: 20;
}
#why-us-container {
	background: black;
}
.container-why-us-image {
	opacity: 0;
	width: 100%;
	display: flex;
	position: relative;
	justify-content: center;
	perspective: 1200px; /* Adjust the perspective value to control the depth effect */
	perspective-origin: center;
	& #why-us-first-bubble-background {
		position: absolute;
		width: 392.4px;
		height: 392.4px;
		left: 70%;
		bottom: -5%;

		/* grad01 */
		background: linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%);
		mix-blend-mode: screen;
		opacity: 0.4;
		filter: blur(29.5px);
		border-radius: 100000px;
		transform: rotate(-1.77deg);
		z-index: -20;
		animation: bubble-why-us-first 5s ease-in-out infinite;
		animation-delay: 0s;
		@media (max-width: 700px) {
			width: 200px;
			height: 200px;
			bottom: -15%;
		}
	}
	& #why-us-second-bubble-background {
		position: absolute;
		width: 392.4px;
		height: 392.4px;
		left: -10%;
		top: -5%;
		z-index: -20;

		/* grad01 */

		background: linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%);
		mix-blend-mode: screen;
		opacity: 0.4;
		filter: blur(29.5px);
		border-radius: 100000px;
		transform: rotate(135deg);
		animation: bubble-why-us-second 5s ease-in-out infinite;
		animation-delay: 1s;
		@media (max-width: 700px) {
			width: 200px;
			height: 200px;
			top: 20%;
		}
	}
	& #why-us-third-bubble-background {
		position: absolute;
		width: 392.4px;
		height: 392.4px;
		left: 40%;
		top: 0%;
		z-index: -20;

		/* grad01 */

		background: linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%);
		mix-blend-mode: screen;
		filter: blur(29.5px);
		border-radius: 100000px;
		transform: rotate(135deg);
		animation: bubble-why-us-third 5s ease-in-out infinite;
		animation-delay: 2s;
		@media (max-width: 700px) {
			width: 200px;
			height: 200px;
			top: 30%;
		}
	}
	& #why-us-fourth-bubble-background {
		position: absolute;
		width: 392.4px;
		height: 392.4px;
		left: 0%;
		bottom: 0%;
		z-index: -20;

		/* grad01 */

		background: linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%);
		mix-blend-mode: screen;
		filter: blur(29.5px);
		border-radius: 100000px;
		transform: rotate(135deg);
		animation: bubble-why-us-fourth 5s ease-in-out infinite;
		animation-delay: 3s;
		@media (max-width: 700px) {
			width: 200px;
			height: 200px;
			bottom: 10%;
		}
	}
}
@keyframes bubble-why-us-first {
	0% {
		transform: rotate(-1.77deg) translateY(0) translateX(0) scale(1);
	}
	50% {
		transform: rotate(90deg) translateX(calc(cos(120deg) * 100px)) translateY(calc(sin(120deg) * 100px)) scale(0.8);
	}
	100% {
		transform: rotate(-1.77deg) translateX(0) translateY(0) scale(1);
	}
}
@keyframes bubble-why-us-second {
	0% {
		transform: rotate(135deg) translateY(0) translateX(0) scale(1);
	}
	50% {
		transform: rotate(50deg) translateX(calc(cos(120deg) * 100px)) translateY(calc(sin(120deg) * 100px)) scale(0.8);
	}
	100% {
		transform: rotate(135deg) translateX(0) translateY(0) scale(1);
	}
}
@keyframes bubble-why-us-third {
	0% {
		transform: rotate(135deg) translateY(0) translateX(0) scale(1);
	}
	50% {
		transform: rotate(180deg) translateX(calc(cos(120deg) * 100px)) translateY(calc(sin(120deg) * 100px)) scale(0.8);
	}
	100% {
		transform: rotate(135deg) translateX(0) translateY(0) scale(1);
	}
}
@keyframes bubble-why-us-fourth {
	0% {
		transform: rotate(135deg) translateY(0) translateX(0) scale(1);
	}
	50% {
		transform: rotate(270deg) translateX(calc(cos(120deg) * 100px)) translateY(calc(sin(120deg) * 100px)) scale(0.8);
	}
	100% {
		transform: rotate(135deg) translateX(0) translateY(0) scale(1);
	}
}
