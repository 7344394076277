.tutorial-skip {
    z-index: 1400;
    position: fixed;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6%;
    background: #434343;
    padding: 1%;
    cursor: pointer;
}

.tutorial-skip:hover {
    background: rgba(67, 67, 67, .7);
}

.tutorial-next-step {
    width: 100% !important;
}

.game-hub-box {
    margin: auto;
}

@keyframes pulse_card {
    0% {
        box-shadow: 0px 0px 20px -20px var(--success);
    }

    100% {
        box-shadow: 0px 0px 20px 5px var(--success);
    }
}