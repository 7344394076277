$color_1: var(--white);
$color_2: #6f6f6f;
$color_3: rgba(255, 255, 255, 0.7);
$color_4: rgba(255, 255, 255, 1);
$color_5: var(--black);
$background-color_1: transparent;
$background-color_2: #6f6f6f;
$background-color_3: #a1a1a1;
$background-color_4: #000000;

@import "./Variables.scss";

/*
.personal-information-field .row:nth-last-child(1) {
	margin-top: 12%;
}*/
.personal-information {
	.btn-fill {
		background: var(--gradient-01);
		color: $color_1;
		padding: 12px 30px;
		border: 1px solid var(--black);
		transition: 0.2s;

		&:hover {
			background: transparent;
			border: 1px solid var(--white);
		}
	}

	margin-top: 60px;

	label {
		font-size: 15px;
		font-weight: 300;
		width: 25%;
	}

	input {
		background-color: $background-color_1;
		border: 1px solid #6f6f6f;
		box-sizing: border-box;
		border-radius: 7px;
		width: 60%;
		display: inline-block;

		&:focus {
			background-color: $background-color_1;
			border: 1px solid rgba(255, 255, 255, 1);
		}
	}
}
.personal-information-field {
	margin: 20px 0;
	display: block;
}

#newUsername {
	width: 100%;
}

.password-field {
	margin: 20px 0;
	display: block;

	.inputContainer {
		display: inline-block;
		margin: 10px;
		margin-left: 0px;
	}
}

.change-password {
	.password-field {
		.label {
			font-size: 15px;
			font-weight: 300;
			width: 35%;
		}
	}

	+ {
		.btn-outline {
			padding: 12px 30px !important;
			margin: 8% 0 0 30%;
		}
	}
}

#select-language-profile {
	margin: 0;
	margin-left: 10px;
}

.btn-change-password {
	display: block !important;
	margin: 30px auto;
	color: $color_2 !important;
	background: rgba(0, 0, 0, 0.5);
	border: 1px solid #6f6f6f !important;
	border-radius: 25px !important;
	padding: 12px 30px !important;

	&:hover {
		color: $color_3 !important;
		background-color: $background-color_2;
	}

	&:active {
		color: $color_4 !important;
		background-color: $background-color_3;
	}
}

.profile-upload {
	position: relative;
	width: 100%;
	display: flex;
	align-items: "center";
	justify-content: center;

	.change-image-button {
		position: absolute;
		cursor: pointer;
		top: 15%;
		right: 15%;
		transform: translate(50%, -50%);
	}

	hr {
		margin-top: 10px;
	}

	input {
		background: none;
		color: white;
		border: none;
		width: 90%;
	}

	input:focus {
		outline: none;
	}

	select {
		background: none;
		color: white;
		border: none;
		width: 100%;
	}

	select:focus {
		outline: none;
	}
}

.loading-personal-information {
	width: 200px !important;
	height: 200px !important;
}

.DotaConnected {
	background: var(--white);
	color: $color_5;
	width: fit-content;
	height: 2.5rem;
	padding-left: 5%;
	padding-right: 5%;
	margin: 0;
	margin-right: 1em;
	border-radius: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.LogoutContainer {
	margin-top: 5% !important;
}

.addGameConnection {
	width: 2.5em;
	height: 2.5em;
	border-radius: 50% !important;
	display: flex !important;
	align-items: center;
	justify-content: center;
	padding: 3% !important;
}

.GameElementConnected {
	margin: 0;
}

.hyperlinkDiscord {
	color: $color_1;
}

.troubleSteamAccount {
	font-size: 12px;
}

#settings-title-container {
	h1 {
		padding-top: 1em;
		padding-bottom: 1em;
	}
}

#button-logout {
	margin-top: 2em;
}

.profile-page-image {
	max-width: 150px;
	aspect-ratio: 1/1;
	border-radius: 50%;
}