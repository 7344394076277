@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5);
	}
	100% {
		box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
	}
}
@keyframes loading {
	0% {
		fill: url(#step1);
	}
	5% {
		fill: url(#step2);
	}
	10% {
		fill: url(#step3);
	}
	15% {
		fill: url(#step4);
	}
	20% {
		fill: url(#step5);
	}
	25% {
		fill: url(#step6);
	}
	30% {
		fill: url(#step7);
	}
	35% {
		fill: url(#step8);
	}
	40% {
		fill: url(#step9);
	}
	45% {
		fill: url(#step10);
	}
	50% {
		fill: url(#step11);
	}
	55% {
		fill: url(#step12);
	}
	60% {
		fill: url(#step13);
	}
	65% {
		fill: url(#step14);
	}
	70% {
		fill: url(#step15);
	}
	75% {
		fill: url(#step16);
	}
	80% {
		fill: url(#step17);
	}
	85% {
		fill: url(#step18);
	}
	90% {
		fill: url(#step19);
	}
	95% {
		fill: url(#step20);
	}
	100% {
		fill: url(#step21);
	}
}
.loading-container {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100vh;
	background: var(--purple-02);
	z-index: 20000;
}
#loading-logo {
	width: 40%;
	padding: 5px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
#loading-path {
	fill: var(--white);
}
.loading-screen-complete {
	border-radius: 3vw 4.8vw 0.5vw 0.5vw;
	animation: pulse 0.6s infinite ease-in-out;
}
.loading-screen-path {
	animation: loading 0.8s linear infinite alternate;
}
@media screen and (max-width: 600px) {
	#loading-logo {
		width: 70%;
	}
	.loading-screen-complete {
		border-radius: 5vw 9vw 1vw 1vw;
	}
}
