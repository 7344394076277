@import "./Variables.scss";
$background-color_1: #000000;
$purple: #ab00c7;

.button-quest {
	border-radius: 20px;
	width: 100%;
	padding: 1em;
	// max-height: 6.5em;
	text-align: left;
	margin-top: 1em;
	user-select: none;

	span {
		opacity: 0.6;
		font-size: 10x;
	}
}

.button-quest-first-background {
	background: linear-gradient(90deg, #7f0094 0.58%, #1c0021 97.84%);
}

.button-quest-second-background {
	background: linear-gradient(90deg, #4b0097 0.58%, #17002e 97.84%);
}

.button-quest-third-background {
	background: linear-gradient(90deg, #11007a 0.58%, #050023 97.84%);
}

.button-quest-fourth-background {
	background: linear-gradient(90deg, #002b7d 0.58%, #000d26 97.84%);
}

.quest-container {
	width: 90%;
	background-color: $background-color_1;
	border-radius: 30px;
	padding: 30px;
	height: 100%;
}

.quest-container-premium {
	background: black;
	border-radius: 30px;
	padding: 30px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
	height: 100%;
	position: relative;
}

@media screen and (max-width: 899px) {
	.quest-container {
		width: 100% !important;
		margin: 0 !important;
	}

	.quest-container-premium {
		margin-left: 1em;
		margin-right: 1em;
	}

	.quest-continer-box {
		padding: 0 1em 0 1em;
	}
}

.unlock-premium-features {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: rgba($color: $purple, $alpha: 0.9);
	z-index: 1;

	>a {
		text-decoration: none;
		color: white;

		&:hover {
			color: white;
			cursor: pointer;
		}
	}
}