$background-color_1: rgba(0, 0, 0, 0);
$background-color_2: #000000;

@import "../styles/Variables.scss";
.SliderTournament {
	background-color: $background-color_1 !important;
	position: relative;
	padding: 5%;
	border-radius: 30px;
	height: 100%;
}
.leaderboard-classic {
	width: 100%;
	background-color: $background-color_2;
	padding: 30px;
	border-radius: 30px;
	margin-top: 30px;
}
.leaderboard-title {
	justify-content: center;
	text-align: center;
}
.BackImage {
	position: absolute;
	width: 100%;
}
@media screen and (max-width: 480px) {
	.DiscordContainer {
		height: 100%;
	}
}
