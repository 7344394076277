.accordion-content {
	overflow: hidden;
	max-width: 0;
	max-height: 0;

	transition: opacity 0.75s ease-out, flex 0.75s ease-out, flex-basis 0.75s ease-out;
	opacity: 0;
	// max-width: fit-content;
	flex: 0;
	flex-basis: 0;
	bottom: 0;
	z-index: -20;
	@media (max-width: 700px) {
		// max-width: fit-content;
		transition: opacity 0.75s ease-out;
	}
	&.accordion-open {
		// display: inline;
		max-width: fit-content;
		max-height: unset;
		opacity: 1;
		flex-basis: 100%;
		flex: 1;
		z-index: 1;
		transition: opacity 0.75s ease-out;
		& .accordion-content-text {
			max-height: fit-content;
		}
	}
}
.accordion-button-customize {
	position: relative;
	display: flex;
	align-items: center;
	width: fit-content;

	text-align: left;
	border: 0;
	border-radius: 0;
	max-width: 300px;
	transform: translateY(100%) scale(0.4);
	opacity: 0;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
	&:hover {
		cursor: pointer;
	}
	&::after {
		flex-shrink: 0;
		width: 1.25rem;
		height: 1.25rem;
		margin-left: 10px;
		margin-right: 20px;

		content: "";
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
		background-repeat: no-repeat;
		background-size: 1.25rem;
		transition: transform 0.2s ease-in-out;
	}
	&:not(.collapsed)::after {
		transform: rotate(-180deg);
	}

	.remove-title-accordion {
		max-width: 100%;
		white-space: nowrap;
		overflow: hidden;
		transition: max-width 0.75s ease-out;
	}
	&:not(.collapsed) {
		align-items: flex-start;
		margin-top: 2.5px;
		transition: all 0.75s ease-out;
		max-width: 50px;

		& .remove-title-accordion {
			max-width: 0;
			opacity: 0;
			transition: max-width 0.75s ease-out, opacity 0.75s ease-out;
			@media (max-width: 700px) {
				transition: opacity 0.75s ease-out;
			}
		}
	}
}
.accordion-inside-accordion {
	@media (max-width: 700px) {
		display: none;
	}
}
.accordion-content-text {
	font-weight: 300 !important;
	font-size: 21px !important;
	line-height: 26px !important;
	max-height: 0;
	@media (max-width: 700px) {
		font-size: 18px !important;
		line-height: 23px !important;
	}
}
