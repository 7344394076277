#our-partners-container {

    h4 {
        text-align: center;
        font-weight: 300;
        line-height: 25.2px;

        @media screen and (max-width: 768px) {
            text-align: left;
            padding-left: 1em;
            font-size: 20px;
        }
    }
}

#our-partners-i3p {
    padding-top: 4em;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
        transform: scale(0.6);
        text-align: left;
    }

    @media screen and (min-width: 768px) {
        text-align: right;
    }
}