#first-bubble-page-background {
	position: absolute;
	width: 484.7px;
	height: 484.7px;
	right: -240px;
	top: -80.26px;

	/* grad01 */

	background: linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%);
	mix-blend-mode: screen;
	opacity: 0.4;
	filter: blur(29.5px);
	border-radius: 100000px;
	transform: rotate(88.23deg);
	@media (max-width: 700px) {
		right: -120px;
		top: -40.26px;
		width: 217.01px;
		height: 217.01px;
	}
}
#second-bubble-page-background {
	position: absolute;
	width: 484.7px;
	height: 484.7px;
	right: -150px;
	top: -230px;

	/* grad01 */

	background: linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%);
	mix-blend-mode: screen;
	opacity: 0.4;
	filter: blur(29.5px);
	border-radius: 100000px;
	transform: rotate(-135deg);
	@media (max-width: 700px) {
		right: -75px;
		top: -115px;
		width: 217.01px;
		height: 217.01px;
	}
}
#third-bubble-page-background {
	position: absolute;
	width: 484.7px;
	height: 484.7px;
	right: -240px;
	top: -240px;

	/* grad01 */

	background: linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%);
	mix-blend-mode: screen;
	filter: blur(29.5px);
	border-radius: 100000px;
	transform: rotate(-135deg);
	@media (max-width: 700px) {
		right: -120px;
		top: -120px;
		width: 217.01px;
		height: 217.01px;
	}
}
#fourth-bubble-page-background {
	position: absolute;
	width: 484.7px;
	height: 484.7px;
	right: -240px;
	top: -180px;

	/* grad01 */

	background: linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%);
	mix-blend-mode: screen;
	filter: blur(29.5px);
	border-radius: 100000px;
	transform: rotate(-135deg);
	@media (max-width: 700px) {
		right: -120px;
		top: -90px;
		width: 217.01px;
		height: 217.01px;
	}
}

.about-us-container {
	position: relative;
	background: #0f0a18;
	scroll-snap-type: y mandatory;

	& .section {
		min-height: 100vh;
		min-height: 100dvh;
		display: flex;
		justify-content: center;
		scroll-snap-stop: always;
		@media (max-width: 700px) {
			min-height: unset;
			margin-top: 30px;
			margin-bottom: 30px;
		}
	}
	& #about-us-title {
		margin-top: 200px;
		margin-bottom: 60px;

		display: flex;
		justify-content: center;
		@media (max-width: 700px) {
			margin-bottom: 120px;
		}
	}
	& .about-us-title-text {
		font-weight: 400;

		font-size: 60px !important;
		line-height: 76px !important;

		@media (max-width: 700px) {
			font-size: 44px !important;
			line-height: 55px !important;
		}
	}
}
