.background-gamehub-game.animated {
	transform: translate(0, 0);
	transition: all 0.5s ease-out;
}
.logo-gamehub-game.animated {
	transition: all 0.5s ease-out;
}
.content-gamehub-game.animated {
	transition: all 0.5s ease-out;
}

.logo-gamehub-game.survey {
	position: absolute;
	width: 120px;
	height: 120px;
	background: #d9d9d9;
	opacity: 0.2;
	border-radius: 10000px;
	left: 50%;
	top: 35%;
	transform: translate(-50%, -50%);
}

.background-gamehub-game.survey {
	width: 100%;
	height: 100%;
	position: absolute;
	background: #d9d9d9;
	opacity: 0.2;
	border-radius: 20px;
}

.game-container {
	z-index: 2;
	position: relative;
	height: 288px !important;
	width: 252px !important;
	border-radius: 20px;
	overflow: hidden;
	img {
		pointer-events: none;
	}
	&:hover {
		.background-gamehub-game.animated {
			z-index: 100 !important;
			opacity: 1 !important;
			transform: scale(1.2);
			transition: all 0.5s ease-out;
		}
		.logo-gamehub-game.animated {
			opacity: 0 !important;
			transition: all 0.5s ease-out;
		}
		.content-gamehub-game.animated {
			opacity: 0 !important;
			transition: all 0.5s ease-out;
		}
	}
	@media (max-width: 600px) {
		height: 157px !important;
		width: 138px !important;
	}
}
#fortnite-gamehub-background {
	width: 100%;
	height: 100%;
	position: absolute;
	opacity: 0.7;
	z-index: 1;
	border-radius: 20px;
}
#dota2-gamehub-background {
	width: 100%;
	height: 100%;
	position: absolute;
	opacity: 0.7;
	z-index: 1;
	border-radius: 20px;
}
#fortnite-gamehub-logo {
	width: 120px;
	height: 120px;
	position: absolute;
	left: 50%;
	top: 35%;
	transform: translate(-50%, -50%);
	z-index: 12;
}
#dota2-gamehub-logo {
	width: 120px;
	height: 120px;
	position: absolute;
	left: 50%;
	top: 35%;
	transform: translate(-50%, -50%);
	z-index: 12;
}
.logo-gamehub-game {
	@media (max-width: 600px) {
		height: 80px !important;
		width: 80px !important;
	}
}
