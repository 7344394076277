$color_1: var(--white);
$font-family_1: Sora;
$font-family_3: "Sora";
$background-color_1: transparent;

@import "./Variables.scss";
/* CTA -> Call To Action */
#page-container {
	transition: filter 0.4s ease-out;
}
.blur {
	filter: blur(8px);
}
.opacity-04 {
	opacity: 0.4;
}
.text-h1 {
	font-family: $font-family_1;
	font-style: normal;
	font-weight: bold;
	font-size: 48px;
	line-height: 60px;
}
.text-h2 {
	font-family: $font-family_1;
	font-style: normal;
	font-weight: bold;
	font-size: 36px;
	line-height: 45px;
}
.text-h2-nobold {
	font-family: $font-family_1;
	font-style: normal;
	font-size: 36px;
	line-height: 45px;
}
.text-h3 {
	font-family: $font-family_1;
	font-style: normal;
	font-weight: bold;
	font-size: 26px;
	line-height: 33px;
}
.text-p-bold {
	font-family: $font-family_1;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
}
.text-p {
	font-family: $font-family_1;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
}
.text-cta {
	font-family: $font-family_3;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
}
.text-label {
	font-family: $font-family_1;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
}
.text-label-with-margin {
	font-family: $font-family_1;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}
.text-label-underlined-opicity {
	font-family: $font-family_3;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	text-align: right;
	text-decoration-line: underline;
	opacity: 0.7;
}
.input-text {
	font-family: $font-family_3;
	border-radius: 25px;
	border: 1px solid var(--white);
	color: $color_1;
	background-color: $background-color_1;
	padding: 8px 10px;
}
