@use "node_modules/react-widgets/scss/styles.scss" with ($input-height: 1em,
	// Most component variables are forwarded with a namespace
	$list-selected-bg: transparent,
	$widget-font-size: 14px,
	$input-padding-x: 0.5em);

// .rw-list-option {
// 	padding-top: 0em !important;
// 	padding-bottom: 0em !important;
// }

// .rw-widget-input {
// 	background-color: rgba($color: #000000, $alpha: 1) !important;
// 	box-shadow: unset !important;
// 	height: -webkit-fill-available;
// }

// .countries-dropdown .rw-widget-input {
// 	border: 0 !important;
// }

// .rw-open {
// 	background-clip: padding-box;
// 	border: 2px solid transparent;

// 	background: linear-gradient(black, black) padding-box, linear-gradient(to right, rgba(171, 0, 199, 1) 0.58%, rgba(0, 117, 255, 1) 97.84%) border-box;
// 	border-radius: 7px;
// }

// .rw-popup {
// 	background-color: rgba($color: #000000, $alpha: 0.8) !important;
// 	color: white;
// }

// .rw-list-option {
// 	color: white !important;
// }

// .rw-list-option:hover:not(.rw-state-selected) {
// 	background: linear-gradient(90deg, #ac00c780 0.59%, #0075ff80 97.84%);
// }

// .rw-state-focus,
// .rw-state-selected {
// 	background-clip: padding-box;
// 	border: 2px solid transparent;

// 	background: linear-gradient(to right, rgba(171, 0, 199, 1) 0.58%, rgba(0, 117, 255, 1) 97.84%) border-box !important;
// }

.history-placements-title .rw-dropdown-list {
	height: 100% !important;
}

.history-placements-title .rw-widget-input {
	height: 100% !important;
}