$color_1: rgba(255, 255, 255, 1);
$background-color_1: #000000;
$background-color_2: rgba(108, 108, 108, 0.3);

@import "../styles/Variables.scss";

.leaderboard-classic {
	width: 100%;
	background-color: $background-color_1;
	border-radius: 30px;
	margin-top: 30px;
}

.leaderboard {
	position: relative;
	text-align: center;
	padding: 0 0;

	.user-pic {
		border-radius: 100%;
		width: 100%;
		max-width: 30px;
		max-height: 30px;
		aspect-ratio: 1 / 1;
	}

	.leaderboard-title {
		margin-bottom: 1.5em;
	}

	.loading-players {
		width: 100%;
		height: 100%;
		z-index: 1000;
		background-color: black;
		position: absolute;

		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		animation: animation-loading-players 0.25s forwards ease-out;

		>.loading-players-container {
			position: relative;
			width: 100%;
			height: 100%;
		}
	}
}

@keyframes animation-loading-players {
	0% {
		width: 0px;
		height: 0px;
		background-color: transparent;
	}

	100% {
		width: 100%;
		height: 100%;
		background-color: black;
	}
}

.leaderboard-title {
	font-size: 22px;
	padding: 0;
	margin: auto;
}

.leaderboard-row {
	// height: 3em;

	// .leaderboard-username {
	// margin: auto;
	// overflow: hidden;
	// text-overflow: ellipsis;
	// }

	&:hover {
		background: linear-gradient(90deg, rgba(171, 0, 199, 0.2) 0.58%, rgba(0, 117, 255, 0.2) 97.84%);
		border-radius: 30px;
	}
}

.border-line {
	margin: 1% auto;
	width: 100%;
	margin-top: 1em;
	margin-bottom: 1.5em;
}

#swiper-leaderboard {
	.swiper-button-next {
		&:after {
			display: none;
		}
	}

	.swiper-button-prev {
		&:after {
			display: none;
		}
	}
}

.game-box-in-leaderboard {
	display: flex;

	button {
		border-radius: 15px;
		margin-left: 0%;
		background: none;
		border: none;
		color: $color_1;
		font: "Sora";
		size: 20px;
		line-height: 29, 62px;
		font-style: normal;

		&:first-child {
			border-radius: 15px;
			padding-left: 1em;
			padding-right: 2em;
		}
	}

	img {
		box-sizing: border-box;
		border: 1px solid #000000;
		border-radius: 10000px;
		max-width: 34.68px;
	}
}

.opacity {
	background-color: $background-color_2 !important;
}

.leaderboard-player-and-distribution {
	width: 100%;
	background-color: $background-color_1;
	border-radius: 30px;
	margin-top: 10px;
	padding: 20px 10px;
}

@media screen and (max-width: 899px) {
	.leaderboard-classic {
		width: unset;
		margin-left: 1rem !important;
		margin-right: 1rem !important;
	}

	.leaderboard-player-and-distribution {
		width: unset;
		margin-left: 1rem !important;
		margin-right: 1rem !important;
	}
}