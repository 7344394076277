@import "./Variables.scss";

.inputContainer {
	width: 100%;
	height: fit-content;
	border-radius: 7px !important;
	position: relative;
	box-sizing: border-box;
	border: 1px solid rgba(255, 255, 255, 0.7);
	display: flex;
	input {
		width: 100%;
		line-height: 25px;
		position: relative !important;
		background: transparent;
		border-radius: 7px !important;
		outline: none !important;
		border: none !important;
		color: var(--white);
		padding: 5px 12px;
		z-index: 2;
		&:focus {
			background: var(--black);
			opacity: 1;
			+ {
				.labelPlaceholder {
					color: var(--purple) !important;
					font-size: 12px;
					line-height: 15px;
					transform: translate(0.625rem, -10px) scale(0.8) !important;
				}
			}
		}
	}
	&:hover {
		border: 1px solid var(--white);
	}
	&:focus-within {
		&::before {
			--border-width: 1px;
			content: "";
			width: calc(100% + (calc(2 * var(--border-width))));
			height: calc(100% + (calc(2 * var(--border-width))));
			position: absolute;
			top: calc(-1 * var(--border-width));
			left: calc(-1 * var(--border-width));
			background-image: var(--gradient-01);
			border-radius: 7px !important;
			z-index: 1;
		}
	}
	input[type="password"] {
		letter-spacing: 2px;
		-webkit-text-security: square;
	}
	.labelPlaceholder {
		position: absolute;
	}
	// input[data-state="empty"] {
	// 	+ {
	.labelPlaceholder {
		color: #ffffff66;
		position: absolute;
		z-index: 3;
		top: -5px;
		left: 0;
		transform: translate(12px, 11px);
		transform-origin: 0% 0%;
		pointer-events: none;
		transition: transform 0.35s, font-size 0.1s;
	}
	// 	}
	// }

	.error {
		position: absolute;
		width: 100%;
		left: 0;
		bottom: -17px;
		color: var(--error);
		font-weight: bold;
		opacity: 1;
	}
	input[data-state="notEmpty"] {
		+ {
			.labelPlaceholder {
				color: var(--purple) !important;
				font-size: 12px;
				line-height: 15px;
				transform: translate(0.625rem, -10px) scale(0.8);
			}
		}
	}

	.password-visibility-toggle-container {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0.25em;
		z-index: 3;
	}
}
.inputContainer.error {
	border: 1px solid var(--error);
	.labelPlaceholder {
		color: var(--error) !important;
	}
}
.inputContainer.success {
	border: 1px solid var(--success);
	.labelPlaceholder {
		color: var(--success) !important;
	}
}
@media screen and (max-width: 400px) {
	.inputContainer {
		.error {
			bottom: -33px;
		}
	}
}
