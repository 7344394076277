@mixin background_bubble(
	$width,
	$height,
	$inset,
	$transform,
	$background,
	$opacity: 0.4,
	$filter: blur(29.5px),
	$z-index: 0,
	$animation: none,
	$animation_delay: none
) {
	position: absolute;

	width: $width;
	height: $height;
	inset: $inset;
	z-index: $z-index;
	/* grad01 */
	background: $background;
	mix-blend-mode: screen;
	opacity: $opacity;
	filter: $filter;
	border-radius: 100000px;
	transform: $transform;

	animation: $animation;
	animation-delay: $animation_delay;
}
