.pop-up-team-member-dialog-container {
	.MuiBackdrop-root {
		background: #0f0a18e7;
        @media (max-width: 700px) {
            background: transparent;
        }
	}
	.MuiDialogContent-root {
		padding: unset !important;
		// padding: 50px;
	}
	.MuiPaper-root {
		border-radius: unset !important;
		background-color: unset !important;
		color: unset !important;
		border: unset !important;
		min-height: 700px;
		background: transparent !important;
		box-shadow: unset !important;
		@media (max-width: 700px) {
			min-height: unset;
		}
		.MuiDialogContent-root {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

.pop-up-team-member-container {
	background: #000000;
	opacity: 0.89;

	/* Note: backdrop-filter has minimal browser support */
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 20px;
	padding-bottom: 20px;

	border-radius: 29px;
	animation: animation-popup 1s forwards;
	@media (min-width: 0px) {
		min-height: unset;
		margin-top: 70px;
		max-width: 100%;
	}
	@media (min-width: 400px) {
		margin-top: unset;
		max-width: 95%;
		min-height: 380px;
	}
	@media (min-width: 700px) {
		box-shadow: 0px 0px 250px rgba(113, 39, 217, 0.3);
		backdrop-filter: blur(237px);
	}
	@media (min-width: 900px) {
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 50px;
		padding-bottom: 50px;
		max-width: 90%;
	}
	@media (min-width: 1300px) {
		max-width: 80%;
	}

	@media (min-width: 1536px) {
		max-width: 70%;
		padding-left: 70px;
		padding-right: 70px;
		padding-top: 50px;
		padding-bottom: 50px;
	}
}

@keyframes animation-popup {
	0% {
		transform: scale(0);
		opacity: 0.5;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

.team-member-links-container {
	background: #1c0a36;
	opacity: 0.89;
	border-radius: 29px;
	margin: 10% 0;
	padding: 10px;
	padding-left: 35px;
	padding-top: 30px;
	padding-bottom: 30px;
}

.team-member-name {
	font-weight: 700 !important;
	font-size: 20px !important;
	line-height: 25px !important;

	color: #ffffff !important;
}
.team-member-role {
	font-weight: 300 !important;
	font-size: 15px !important;
	line-height: 19px !important;
	margin-top: 8px !important;
}
.team-member-description {
	font-weight: 300 !important;
	font-size: 20px !important;
	line-height: 25px !important;
	@media (max-width: 700px) {
		font-size: 17px !important;
		line-height: 21px !important;
	}
}
.team-member-favourite-game {
	display: flex;
	// justify-content: center;
	align-items: center;
	gap: 10px;
	margin-bottom: 28px !important;
	@media (max-width: 700px) {
		margin-bottom: 14px !important;
	}
	img {
		height: 33px;
		width: 33px;
		border-radius: 50%;
	}
	.team-member-favourite-game-text {
		font-weight: 300 !important;
		font-size: 20px !important;
		line-height: 25px !important;
		@media (max-width: 700px) {
			font-size: 17px !important;
			line-height: 21px !important;
		}
	}
}
.team-member-favourite-game-title {
	font-weight: 300 !important;
	font-size: 14px !important;
	line-height: 16px !important;
}
.team-member-image-container {
	@media (max-width: 700px) {
		margin-top: -86px !important;
	}
	.team-member-image {
		width: 100%;
		aspect-ratio: 1/1;
		@media (max-width: 700px) {
			width: 132px;
			height: 132px;
		}
	}
}
.team-member-info-container {
	padding: 0px 45px;
	@media (max-width: 700px) {
		padding: 0px 5px;
	}
}

.team-member-links {
	color: white;
	font-weight: 300;
	font-size: 20px;
	line-height: 25px;
	&:hover {
		color: white;
	}
	@media (max-width: 700px) {
		font-size: 14px;
		line-height: 18px;
	}
}
