.signup-container {
	position: relative;
	min-height: 100vh;
	min-height: 100dvh;
	z-index: 100;
	> * {
		z-index: 20 !important;
	}

	.privacy-policies-link {
		color: white;
	}
}
#gradient-canvas-sign-up {
	width: 100%;
	height: 100%;
	--gradient-color-1: #659fe4;
	--gradient-color-2: #e69ff1;
	--gradient-color-3: #7b00c7;
	--gradient-color-4: #0075ff;
	z-index: -20 !important;
	position: absolute;
	top: 0;
	left: 0;
}

#canvas-container-sign-up {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;

	.canvas-wrapper {
		position: relative;
		width: 100%;
		height: 100%;
	}
}
