$color_1: var(--white);
$color_2: rgb(255, 167, 38);
$color_3: #ffffff;
$background-color_1: rgb(255, 167, 38, 0.8);

@import "./Variables.scss";

@keyframes pulse_banner {
	0% {
		box-shadow: 0px 0px 20px -20px var(--blue);
	}
	100% {
		box-shadow: 0px 0px 20px 5px var(--blue);
	}
}
.game-hub {
	margin: 80px 0;
	text-align: center;
}
.game-tabs {
	.react-tabs__tab-list {
		float: none;
		width: 36%;
		display: block;
		margin: 10px auto;
		border-radius: 30px;
		padding: 4px 0 6px 0;
		border: none;
		.react-tabs__tab {
			font-size: 17px;
			font-weight: 300;
			padding: 5px 20px 5px 20px;
		}
		.react-tabs__tab--selected {
			background: linear-gradient(90deg, rgba(171, 0, 199, 0.7) 0.58%, rgba(0, 117, 255, 0.7) 97.84%);
			border-radius: 30px;
			border: none;
			color: $color_1;
			&:focus {
				&:after {
					height: 0;
				}
			}
		}
	}
	h1 {
		font-size: 36px;
		font-weight: 600;
	}
	h3 {
		font-size: 28px;
		margin: 50px 0;
	}
}
.verification-email {
	text-align: center;
}
.btn-verification {
	margin-top: 8%;
	color: $color_2 !important;
	background: rgba(0, 0, 0, 1) !important;
	border: 1px solid rgb(255, 167, 38) !important;
	border-radius: 25px !important;
	padding: 7px 20px !important;
	&:hover {
		color: $color_3 !important;
		border: 1px solid rgba(255, 167, 38, 0.8) !important;
		background-color: $background-color_1 !important;
		border-radius: 25px !important;
		padding: 7px 20px !important;
	}
}
@media screen and (max-width: 280px) {
	.game-tabs {
		.react-tabs__tab-list {
			width: 100%;
		}
	}
}
.swiper-slide {
	width: auto
}
#title-gamehub {
	@media screen and (max-width: 946px) {
		font-size: 20px;
	}
}
#title-space {
	@media screen and (max-width: 946px) {
		display: none;
	}
}
#swiper-container-landing-gamehub {
	margin-top: 1em;
}