@import "./Variables.scss";
/* **************** Home first section  ****************** */
/* width */
/* Track */
/* Handle */
/* Handle on hover */
html {
	width: 100%;
	height: 100%;
	margin: 0px;
	padding: 0px;
	scrollbar-width: thin;
	scrollbar-color: #dadada transparent;
	overflow-x: hidden !important;
	position: relative;
}
body {
	width: 100%;
	height: 100%;
	margin: 0px;
	padding: 0px;
	scrollbar-width: thin;
	scrollbar-color: #dadada transparent;
	overflow-x: hidden !important;
	position: relative;
}
#root {
	position: relative;
}
.website-landing {
	background: radial-gradient(61.55% 10.49% at 96.7% 5.97%, rgba(58, 66, 210, 0.39) 0%, rgba(58, 66, 210, 0) 100%), radial-gradient(137.03% 17.86% at 0% 1.09%, rgba(171, 0, 199, 0.26) 0%, rgba(171, 0, 199, 0) 100%), radial-gradient(70.5% 10.56% at 96.27% 21.31%, rgba(171, 0, 199, 0.26) 0%, rgba(171, 0, 199, 0) 100%), radial-gradient(60.23% 10.34% at 19.27% 24.24%, rgba(0, 183, 139, 0.25) 0%, rgba(0, 183, 139, 0) 83.73%), radial-gradient(85.2% 14.67% at 54.13% 56.98%, rgba(25, 86, 203, 0.46) 0%, rgba(79, 25, 203, 0) 100%), radial-gradient(72.94% 12.27% at 15.83% 38.86%, rgba(58, 66, 210, 0.29) 0%, rgba(58, 66, 210, 0) 100%), radial-gradient(71.3% 10.77% at 104.9% 34.36%, rgba(171, 0, 199, 0.33) 0%, rgba(171, 0, 199, 0) 100%), #1b011f;
}
::-webkit-scrollbar {
	width: 4px;
	border-radius: 1px;
}
::-webkit-scrollbar-track {
	background: var(--purple-02);
}
::-webkit-scrollbar-thumb {
	background: #7805ca;
	border-radius: 1px;
	&:hover {
		background: #9808ff;
	}
}
@media screen and (min-width: 480px) {
	.website-landing {
		padding-top: 15%;
	}
}
@media screen and (max-width: 480px) {
	.website-landing {
		padding-top: 20%;
	}
}
@media only screen and (min-width: 700px) {
	.hide-on-big-screen {
		display: none;
	}
}
@media only screen and (max-width: 500px) {
	.main-website {
		display: block;
	}
}
