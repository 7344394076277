@import "./Variables.scss";
/* MAIN CONTAINER */
/* ON HOVER */
/* WHEN OPENED */
/* HEADER */
/* WHEN OPENED */
/* WHEN NOT EMPTY */
/* WHEN EMPTY */
/* ARROW UP */
/* LIST CONTAINER */
/* BLANK ITEM */
/* ITEM */
/* FIRST ITEM */
/* LAST ITEM */
/* ON HOVER */
/* PLACEHOLDER */
/* WHEN OPENED */
.SelectLanguageWrapper {
	.select-container {
		position: relative;
		left: -40%;
		
	}
}
.select-container {
	position: relative;
	width: fit-content;
	margin: 20px;
	.select-header-placeholder {
		color: rgba(255, 255, 255, 0.4);
		position: absolute;
		z-index: 3;
		top: -6px;
		left: 0;
		transform: translate(12px, 11px);
		transform-origin: 0% 0%;
		pointer-events: none;
		transition: transform 0.35s, font-size 0.35s;
	}
}
.select-container[data-open="true"] {
	&:before {
		content: "";
		position: absolute;
		background-image: rgba(0, 0, 0, 0);
		z-index: 1;
	}
	.select-header {
		background: rgba(255, 255, 255, 0.4);
		z-index: 2;
	}
	svg {
		color: var(--blue);
	}
	.select-header-placeholder {
		color: var(--purple);
		font-size: 12px;
		line-height: 15px;
		transform: translate(0.625rem, -10px) scale(0.8);
	}
}
.select-header {
	height: fit-content;
	position: relative !important;
	background: transparent;
	border-radius: 7px !important;
	outline: none !important;
	border: none !important;
	font-size: 14px;
	color: var(--white);
	padding: 5px 12px;
	flex: content;
	svg {
		padding-left: 10px;
		padding-top: 4px;
	}
}
.select-container[data-open="true"][data-empty="false"] {
	.select-header-title {
		display: flex;
		justify-content: center;
	}
}
.select-container[data-open="false"][data-empty="false"] {
	.select-header-title {
		display: flex;
		justify-content: center;
	}
}
.select-header-title {
	display: flex;
	justify-content: center;
}
.select-container[data-open="true"][data-empty="true"] {
	.select-header-title {
		display: flex;
		justify-content: center;
	}
}
.select-list {
	position: absolute;
	display: inline-grid;
	width: 100%;
	top: 37px;
	left: 0;
	border-radius: 7px;
	z-index: 3;
}
.select-list-item.item-blank {
	padding: 20px 0;
}
.select-list-item {
	background: rgba(0, 0, 0, 0);
	color: var(--white);
	border: none;
	padding: 0;
	text-align: center;
	padding: 4px;
	&:hover {
		background: rgba(255, 255, 255, 0.4);
		background: rgba(255, 255, 255, 0.4);
	}
	&:first-child {
		border-radius: 7px 7px 0 0;
	}
	&:last-child {
		border-radius: 0 0 7px 7px;
	}
}

@media (max-width: 768px) {
	.select-header-rectangle {
		position: relative;
		width: 35px;
		height: 5px;
		background: linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%)
	}
}
