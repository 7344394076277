#container-how-it-works {
	position: relative;
	// margin-top: 70px;
	// margin-bottom: 70px;

	@media (max-width: 700px) {
		// min-height: 200vh;
		width: 100%;
		min-height: 200dvh;
		scroll-snap-type: y mandatory;

		&.block-scrolling-container {
			// height: 200vh;
			// height: 200dvh;
		}
	}
}

.text-how-it-works {
	font-weight: 300 !important;
	font-size: 38px !important;
	line-height: 45px !important;
	width: 33%;

	// opacity: 0;
	// animation: opacity-animation 1s ease-out;
	@media (max-width: 1500px) {
		font-size: 35px !important;
		line-height: 40px !important;
		width: 40%;
	}

	@media (max-width: 1200px) {
		font-size: 30px !important;
		line-height: 35px !important;
		width: 40%;
	}

	@media (max-width: 900px) {
		// width: 40%;
		font-size: 25px !important;
		line-height: 30px !important;
	}

	@media (max-width: 700px) {
		font-size: 20px !important;
		line-height: 25px !important;
		width: 80%;
		opacity: 0;
		transition: all 1s ease-out;
		position: fixed;

		transform: translate(0, 50%);

		left: 23px;

		&.showing {
			opacity: 1;
			position: relative;
		}
	}
}

#content-how-it-works {
	@media (max-width: 700px) {
		height: 90vh;
		height: 90dvh;
		width: 100%;
		scroll-snap-stop: always;

		&.block-scrolling {
			position: sticky;
			position: -webkit-sticky;
			top: 0;
			left: 0;
		}
	}
}

.line-about-us-section {
	position: absolute;
	top: 0;
	left: 0;
	width: 0%;
	// max-height: 70px;
	overflow: hidden;
	transition: all 3s ease-out;
	transform: translate(0, -50%);

	@media (max-width: 1800px) {
		top: 20%;
	}

	@media (max-width: 1600px) {
		top: 15%;
	}

	@media (max-width: 1500px) {
		top: 15%;
	}

	@media (max-width: 1400px) {
		top: 12%;
	}

	@media (max-width: 1100px) {
		top: 25%;
	}

	@media (max-width: 1000px) {
		top: 30%;
	}

	@media (max-width: 900px) {
		top: 27%;
	}

	@media (max-width: 800px) {
		top: 35%;
	}

	@media (max-width: 700px) {
		top: 15%;
		width: 100%;
		height: fit-content;
		transform: unset;
		transition: all 0.25s ease-out;
	}
}

.line-about-us-section-animation {
	width: 100%;
	// max-height: 1000px;
	transition: all 3s ease-out;
}

.line-about-us {
	width: 100vw;
	z-index: 0;
}

.how-it-works-shop-text {
	width: unset;
	font-weight: 300 !important;
	font-size: 30px !important;
	line-height: 38px !important;

	@media (max-width: 700px) {
		font-size: 18px !important;
		line-height: 22px !important;
	}
}

.how-it-works-gitfcard-text {
	font-weight: 600 !important;
	font-size: 78px !important;
	line-height: 98px !important;

	@media (max-width: 700px) {
		font-size: 40px !important;
		line-height: 50px !important;
	}
}