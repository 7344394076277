.dashboard-admin-container {
	display: flex;
	flex-direction: column;
	align-items: center;

	gap: 1em;

	hr {
		width: 100%;
		border-top: 2px solid white;
		opacity: 1;
	}

	// table {
	// 	width: 100%;
	// 	border: 2px solid black;
	// 	border-collapse: unset;

	// 	& thead {
	// 		border: 2px solid black;

	// 		th {
	// 			border: 2px solid black;
	// 			padding: 1em;
	// 			text-align: center;
	// 		}
	// 	}

	// 	& tbody {
	// 		border: 2px solid black;

	// 		& tr {
	// 			border: 2px solid black;

	// 			& td {
	// 				border: 2px solid black;
	// 				padding: 1em;
	// 				text-align: center;
	// 			}
	// 		}
	// 	}



	// }


}

.paragraph-no-wrap {
	white-space: nowrap;
}