* {
	/* COLORS */
	--gradient-01: linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%);
	--gradient-01-30: linear-gradient(90deg, rgba(171, 0, 199, 0.3) 0.58%, rgba(0, 117, 255, 0.3) 97.84%);
	--blue: #0176ff;
	--purple: #ab00c7;
	--purple-02: #261533;
	--error: #880946;
	--success: #27ae60;
	--black: #000000;
	--white: #ffffff;

	/* BACKGROUNDS */
	--background: #1b011f;
	--background-purple-30: #ab00c74d;
	--background-blue-20: #0176ff33;
	--background-violet-30: #4f19cb4d;
	--background-green-30: #00b78b4d;

	box-shadow: none;
}

$purple: #ab00c7;
