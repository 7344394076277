@import "../styles/Variables.scss";
.gamer-box {
	position: relative;
	border-bottom-right-radius: 25px;
	background: rgba(0, 0, 0, 0.6);
	&:before {
		content: "";
		position: absolute;
		top: 0;
		border-style: solid;
		border-color: transparent transparent transparent #ededed;
		border-width: 0 0 20px 22px;
		left: auto;
		right: -21px;
		border-left-color: rgba(0, 0, 0, 0.6);
	}
	.user-profile {
		border-radius: 50%;
		width: 120%;
		height: 120%;
	}
}
.gamer-box-badges {
	display: flex;
	justify-content: space-between;
	align-items: center;
	user-select: none;
}
.gamer-box-badges-content {
	width: 100%;
	display: flex;
	justify-content: left;
	overflow: hidden;
}
.image-box {
	margin: 1em;
	width: 95px;
	height: 95px;
	background-position: center;
	background-size: cover;
	border-radius: 50%;
}
.gamer-box-container {
	display: flex;
	flex-direction: row;
}
.gamer-box-content {
	margin-top:10px;
	margin-left: 10px;
	label {
		font-weight: 300;
		font-size: 17px;
		margin-bottom: 5px;
		opacity: 1;
	}
}
.progress-bar-main {
	margin: 0 0 0 2vw;
	width: 82%;
	display: inline-block;
}
#user-progressbar {
	background: #9000ff;
	border-radius: 40px;
	.progress-bar {
		background: linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%);
	}
}
.progress-bar-section {
	span {
		font-weight: 300;
		vertical-align: middle;
		color: #ffffff;
		opacity: 0.3;
	}
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 5px;
}
#dashboard-settings-container {
	.box {
		min-height: none;
	}
}
.box {
	position: relative;
	padding: 30px;
	border-radius: 30px;
	background: #000000;
	margin: 1em;
	min-height: 20em;
}
.gamer-box-wrapper {
	padding: 1em;
	padding-left: 0;
	flex-grow: 2;
}
.gamer-box-header-mobile-bolts {
	display: none;
	width: 100%;
}
.gamer-box-header-mobile-lev {
	display: none;
	width: 100%;
}
@media screen and (max-width: 1200px) {
	.gamer-box {
		.user-profile {
			width: 8rem;
			height: 8rem;
		}
	}
	.loading-item.profile-pic {
		width: 8rem;
		height: 8rem;
	}
	.image-box {
		.loading-item.profile-pic {
			margin-left: 50%;
			transform: translate(-50%);
		}
	}
	.gamer-box-wrapper {
		padding: 1em;
	}
}
@media only screen and (max-width: 899.9px) {
	.gamer-box {
		display: none;
	}
	.gamer-box-header-mobile-bolts {
		display: inline;
		width: 100%;
	}
	.gamer-box-header-mobile-lev {
		display: inline;
		width: 100%;
	}
}
