$input-background-color: #000000;
$input-text-color: #ffffff;
$button-confirm-color: #ffffff;
$font-family: "Sora";

.form-input-field {
	background: $input-background-color;

	.Mui-focused {
		border-color: red;
	}
	.MuiOutlinedInput-notchedOutline {
		border-color: transparent !important;
	}

	.MuiInputLabel-outlined,
	.MuiInputBase-input {
		color: $input-text-color !important;
		font-family: $font-family;
		font-weight: 300;
		font-size: 16px;
		line-height: 20px;
		opacity: 0.4;
	}
	.MuiInputLabel-outlined.Mui-focused {
		opacity: 1 !important;
		top: -7px;
		display: none;
	}

	.MuiInputBase-input:not([value=""]) {
		opacity: 1;
	}
	.MuiIconButton-root {
		color: white;
	}
}

.form-container {
	text-align: center;

	.MuiButton-contained {
		background-color: $button-confirm-color;
		color: black;
		border-radius: 50px;
		font-family: $font-family;
		text-transform: none;

		@media screen and (min-width: 873px) {
			width: 40%;
		}
	}

	// img {
	// 	@media screen and (max-width: 768px) {
	// 		display: none;
	// 	}
	// }

	@media screen and (min-width: 768px) {
		.MuiTextField-root {
			width: 50%;
		}
	}
}
