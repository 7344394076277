$color_1: #c4c4c4;
$color_2: white;
$color_3: transparent;
$color_4: var(--white);
$color_5: rgba(255, 255, 255, 0.6);
$color_6: #ffffff;
$color_7: rgba(255, 255, 255, 0.4);
$color_8: #0176ff;
$font-family_1: "Sora";
$background-color_1: rgba(0, 0, 0, 0);
$background-color_2: transparent;
$background-color_3: red;
$background-color_4: rgb(255, 255, 255, 0.2);
$background-color_5: rgba(0, 0, 0, 0.9);

@import "./Variables.scss";

/* SLIDER NEWS */
@keyframes pulse_banner {
	0% {
		box-shadow: 0px 0px 20px -20px var(--blue);
	}

	100% {
		box-shadow: 0px 0px 20px 5px var(--blue);
	}
}

/******************LEADERBOARD********************/
/* ********* Notification box *************** */
/* mobile */
/* @media screen and (max-width: 1400px) {
	.timer-distribution-container {
		display: none;
	}
} */
/* ********* Notification box *************** */
/* ********* Activities box *************** */
/* ******** Notification filter *********** */
/*************************Slider BOX PRIRE - BOLT MARKETCAP****************************/
.mobile-line {
	display: none;
}

.slider-news {
	height: fit-content;

	.swiper-slide {
		height: auto;
	}

	.swiper-container {
		height: auto;
	}

	.DiscordContainer {
		position: relative;
		padding: 5%;
		margin: 0;
		border-radius: 30px;
		height: 100%;
		background-color: $background-color_1 !important;
	}
}

.swiper-button-next {
	right: 5% !important;
	width: fit-content;
	top: 0;
	bottom: 0;
	margin: auto 0;
	justify-content: flex-end !important;
	color: $color_1;

	&:hover {
		color: $color_2;
	}
}

.swiper-button-prev {
	left: 5% !important;
	width: fit-content;
	top: 0;
	bottom: 0;
	margin: auto 0;
	justify-content: flex-start !important;
	color: $color_1;

	&:hover {
		color: $color_2;
	}
}

.box-slider-news {
	position: relative;
	padding: 30px;
	border-radius: 30px;
	background: #000000;
	margin: 30px 0;
}

#discord-wrapper {
	height: 100%;
}

.tournament-banner {
	width: 100%;
	height: 100%;
	margin: 0;
	border-radius: 30px;
	background-size: cover;
	position: relative;
	transition: transform 1s;
	animation: pulse_banner 1s infinite alternate;

	&::before {
		content: "";
		width: 100px;
		height: 100px;
		display: block;
		background: linear-gradient(red, #560c0c);
		position: absolute;
		right: 0;
		top: 0;
		clip-path: polygon(0 0, 40% 0, 100% 60%, 100% 100%);
	}
}

.tournament-banner-wrapper-content {
	padding: 7%;
}

.pulse-animation-my-position {
	border-radius: 30px;
	width: 100%;
	background: linear-gradient(90deg, rgba(171, 0, 199, 0.2) 0.58%, rgba(0, 117, 255, 0.2) 97.84%);
}

.react-tabs__tab-list {
	border: none;
	float: right;
	margin-top: 7px;

	.react-tabs__tab--disabled {
		color: $color_5;
	}

	li {
		img {
			width: 36px;
		}
	}
}

.react-tabs__tab--disabled {
	&:hover {
		.disable-tab-tooltip {
			display: block;
		}
	}
}

.react-tabs__tab {
	border: none;
	color: $color_6;
	padding: 6px 6px 6px 0;
	margin: 0 12px;
	font-weight: 300;

	&:last-of-type {
		padding: 6px 6px 6px 6px;
	}
}

.react-tabs__tab--selected {
	background-color: $background-color_2;
	border-bottom: 3px solid #ffffff;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.swipeable-list {
	overflow: initial !important;
	margin: 10px 0;
}

.swipeable-list-item {
	overflow: initial !important;
}

.swipeable-list-item__content {
	background-color: $background-color_2 !important;

	.MuiGrid-root {
		margin-top: 0 !important;
	}
}

.swipeable-list-item__content-left {
	border-radius: 30px;
	background-color: $background-color_3;
	text-align: center;
	padding-right: 4%;
}

// .leaderboard-row {
// 	&:hover {
// 		background: linear-gradient(90deg, rgba(171, 0, 199, 0.2) 0.58%, rgba(0, 117, 255, 0.2) 97.84%);
// 		border-radius: 30px;
// 	}
// }
.timerDistribution {
	display: flex;
	justify-content: flex-end;
	align-items: center;

	p {
		display: flex;
		white-space: nowrap;
		margin: 0;

		p {
			opacity: 0.5;
			margin: 0;
		}
	}

	span {
		margin: 0;
	}
}

@media screen and (max-width: 1400px) {
	.timerDistribution {
		display: none;
	}
}

.total-daily-players-container {
	padding-left: 1rem;
	white-space: nowrap;
}

.timer-distribution-container {
	padding-right: 1rem;
}

.notification-box-content {
	padding: 10px 0;
	padding-left: 10px;
	border-radius: 30px;
	overflow: auto;
	flex-wrap: nowrap !important;

	&:hover {
		background: var(--gradient-01-30);
	}

	.text-label {
		opacity: 0.4;
	}

	.crossNotifications {
		cursor: pointer;
		float: right;
		color: $color_7;
		margin-right: 25px;

		&:hover {
			cursor: pointer;
			float: right;
			color: $color_4;
		}
	}
}

.notification-content-text {
	margin-left: 2% !important;
}

.cross-all-notifications {
	float: right;
	cursor: pointer;
	color: $color_7;
	margin-right: 10px;

	&:hover {
		color: $color_4;
	}
}

.activities-title {
	font-size: 22px;
}

.activities-lock-box {
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 0;
	right: 0;
	display: block;
	margin: 0px auto;
	width: 60%;
	border-radius: 30px;
	text-align: center;
	background-color: $background-color_4;
	padding: 30px 5px;

	img {
		margin-bottom: 25px;
	}

	p {
		font-size: 13px;
		color: $color_6;
		opacity: 0.6;
	}
}

.notification-alert {
	width: 100%;
	position: relative;
	top: 20px;
	border-radius: 30px 0px 0px 30px;
	display: none;

	label {
		display: block;
		font-size: 14px;

		span {
			opacity: 0.4;
		}
	}

	span {
		font-size: 13px;
		font-weight: 400;
	}
}

.notification-alert-div1 {
	float: right;
	width: 40%;
	background-image: linear-gradient(to right, rgba(171, 0, 199, 0.5) 0%, rgba(0, 117, 255, 0.5) 100%);
	padding: 2px 0 2px 1px;
	border-radius: 30px 0px 0px 30px;

	&:hover {
		background-image: linear-gradient(to right, rgba(171, 0, 199, 1) 0%, rgba(0, 117, 255, 1) 100%);
	}
}

.notification-alert-div2 {
	position: relative;
	float: right;
	width: 99.9%;
	background-color: $background-color_5;
	border-radius: 30px 0px 0px 30px;
	padding: 12px;
}

.notification-alert-image {
	margin-right: 15px;
	float: left;
	display: inline-block;
}

.notification-alert-content {
	padding-top: 7px;

	.cross {
		cursor: pointer;
		float: right;
		margin-top: 0px;
		margin-right: 10px;
		width: 16px;
		color: $color_7;

		&:hover {
			color: $color_4;
		}
	}
}

.box-slider {
	position: relative;
	padding: 30px;
	border-radius: 30px;
	background: #000000;
	margin: 30px 0;
	height: 100% !important;
}

.slider-title {
	display: inline-block;
	font-size: 22px;
}

.slider-header {
	float: right;
	display: inline-block;

	label {
		margin: 0 10px 0 30px;
		font-size: 22px;
	}

	.return-status {
		font-size: 12px;
		margin-right: 10px;
	}

	.activity-status {
		font-size: 12px;
		color: $color_8;
	}
}

.disable-tab-tooltip {
	display: none;
	text-align: center;
	position: absolute;
	z-index: 410;
	background: rgba(255, 255, 255, 0.2);
	backdrop-filter: blur(12px);
	border-radius: 30px;
	padding: 15px 25px;
	right: 0px;
	top: 65px;
	width: 210%;

	p {
		margin-top: 15px;
		margin-bottom: 0;
		font-size: 13px;
		color: $color_6;
	}
}

.swiper-bolts-analysis {
	height: fit-content;
	margin-top: 10%;
	margin-bottom: 10%;
}

.MuiPaginationItem-ul {
	color: $color_2;
}

#main-container-home {
	top: 17vw;
}

#home-container-wallpaper-content {
	position: relative;
	bottom: 7em;
	left: 2em;

	h2 {
		position: relative;
		margin-bottom: 1em;
		position: relative;
		margin-bottom: 1em;
	}
}

.background-progression-quest {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: -1;
	border-radius: 20px;
}

.background-quest-content {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 1em;
	margin: 0.5em !important;
	gap: 7px;
	background: linear-gradient(90deg, rgba(171, 0, 199, 0.5) 0.59%, rgba(0, 117, 255, 0.5) 97.84%);
	border-radius: 20px;
	z-index: 2;
	min-height: 7em;

	h5 {
		text-align: center;
		font-family: $font-family_1;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 18px;
		margin: 0;
		align-items: center;
		text-align: center;
		color: $color_6;
	}

	label {
		text-align: center;
		font-family: $font-family_1;
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;
		color: $color_6;
		opacity: 0.6;
	}
}

.no-game-connected-text {
	margin: 0;
	line-height: 1.5;
}

@media screen and (max-width: 480px) {
	.slider-news {
		.DiscordContainer {
			height: 100%;
			aspect-ratio: 0;
		}
	}
}

@media screen and (max-width: 1199px) {
	.notification-alert {
		display: none !important;
	}
}

@media screen and (min-width: 0px) and (max-width: 400px) {
	.card-storm {
		position: relative;
		top: 20px;
		bottom: 20px;
	}
}

@media screen and (max-width: 905px) {
	#home-container-wallpaper-content {
		display: none;
	}
}

.hero-cut-dashboard {
	width: 100%;
	aspect-ratio: 160/23;
	position: relative;

	bottom: calc(100vw * 23 / 160);
	left: 0;

	@media (max-width: 700px) {
		display: none;
	}
}