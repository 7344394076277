$color_1: #ffffff;
$color_2: var(--white);
$color_3: rgb(244, 67, 54);
$color_4: rgb(255, 167, 38);
$color_5: rgb(41, 182, 246);
$color_6: rgb(102, 187, 106);
$color_7: red;
$font-family_1: "Sora", sans-serif;
$font-family_2: Sora;
$background-color_1: rgba(0, 0, 0, 0.8);
$background-color_2: #fff;
$background-color_3: rgba(0, 0, 0, 0.3);
$background-color_4: rgba(0, 0, 0, 0.9);
$background-color_5: rgb(255, 167, 38, 0.8);

@import "../styles/Variables.scss";
/* * {
  border: .1px solid red;
} */
/*.main {
    height: 100vh;

}*/
/* #nav-link-market img {
  opacity: 0.5;
} */
/* 
@media screen and (min-width: 501px) {
	.navbar-on-mobile-dashboard {
		display: none;
	}
} */
/* ***************** Profile tab ******************* */
/* 

.error{
    display: none;
    color:#e71f1f!important;
    opacity: 1 !important;
    font-weight: bold;
  } */
/* DIALOG */
/* CONTENT DIALOG */
/* TITLE DIALOG 
.MuiTypography-root {
	display: flex;
	justify-content: center;
	padding: 0 !important;
}*/
body {
	background: linear-gradient(90deg, rgb(38 0 44) 0.58%, rgb(36, 0, 102) 97.84%);
	padding: 0 !important;
	margin: 0 auto !important;
	color: $color_1 !important;
	font-family: $font-family_1 !important;
	text-decoration: none !important;
	overflow-x: hidden;
}
label {
	all: unset;
}
.alert {
	font-family: $font-family_1;
	color: $color_1 !important;
	position: fixed !important;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border: 1px solid #ffffff !important;
	background-color: $background-color_1;
	z-index: 1310;
	display: none;
	.alert-heading {
		span {
			transform: translateY(20%);
			margin-right: 0.5rem;
		}
	}
}
.personal-bg-dark {
	--bs-bg-opacity: 1;
	background: black;
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.navbar-nav {
	.nav-link {
		display: flex;
		justify-content: center;
		align-items: center;
		svg {
			padding: 6px;
		}
	}
	a#underline-element {
		color: $color_2;
	}
}
#nav-bar-storm {
	padding: 0em;
}
.nav-link.disabled {
	img {
		opacity: 0.25;
	}
}
.text-on-navbar {
	line-height: 20px;
	font-size: 16px;
	font-style: normal;
	font-family: $font-family_2;
	padding-left: 0.3rem;
	&:hover {
		cursor: pointer;
	}
}
#underline-element {
	position: absolute;
	width: 0.4em;
	height: 0.4em;
	background-color: $background-color_2;
	border-radius: 0.2em;
	bottom: 10%;
	transform: translate(-50%, 0);
	transition: all 0.4s ease-in-out;
}
#stats-icon-navbar {
	width: 2.2em;
	margin-right: 1em;
}
.alert-heading {
	padding-right: 3rem !important;
}
.alertError {
	color: $color_3 !important;
	border: 1px solid rgb(244, 67, 54) !important;
}
.alertWarning {
	color: $color_4 !important;
	border: 1px solid rgb(255, 167, 38) !important;
}
.alertInfo {
	color: $color_5 !important;
	border: 1px solid rgb(41, 182, 246) !important;
}
.alertSuccess {
	color: $color_6 !important;
	border: 1px solid rgb(102, 187, 106) !important;
}
.mask {
	overflow: hidden !important;
	position: absolute !important;
	display: none;
	width: 100%;
	height: 100vh;
	background-color: $background-color_3;
	z-index: 1309;
}
.dashboard-logo {
	width: 138px;
}
#dashboard-header-container {
	max-width: 100%;
	padding-left: 0% !important;
	padding-right: 0% !important;
}
.notification-title {
	font-size: 22px;
}
.border-line {
	height: 2px !important;
	opacity: 1;
	background: linear-gradient(to right, #ab00c7 0%, #ab00c7 0.01%, #0075ff 100%);
}
.MuiPaper-root {
	width: 30%;
	border-radius: 20px !important;
	background-color: $background-color_4 !important;
	color: $color_2 !important;
	border: 1px solid rgba(255, 255, 255, 0.5);
}
.MuiDialogContentText-root {
	color: $color_2 !important;
}
.MuiTypography-root {
	p {
		margin-bottom: 0;
	}
}
.dialog-confirmation {
	color: $color_7 !important;
	a {
		text-align: center;
		text-decoration: none;
		cursor: pointer;
		width: 50%;
	}
	.close-dialog-confirmation {
		display: flex;
		flex-direction: column !important;
		justify-content: center !important;
		padding: 20px 24px;
	}
}
.dialog-buy {
	a {
		text-align: center;
		text-decoration: none;
		cursor: pointer;
		width: 30%;
	}
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
	max-width: 500px;
	width: 100%;
	padding: 20px 10px;
}
a.tournament-alert {
	text-align: center;
	text-decoration: none;
	color: $color_4;
	border: 1px solid rgb(255, 167, 38);
	cursor: pointer;
	&:hover {
		color: $color_1;
		border: 1px solid rgba(255, 167, 38, 0.8);
		background-color: $background-color_5;
	}
}
@media screen and (max-width: 501px) {
	#nav-bar-storm {
		padding-bottom: 1em;
		padding-top: 1em;
	}
}
@media screen and (min-width: 501px) {
	.hide-on-big-screen {
		display: none;
	}
}
@media screen and (max-width: 510px) {
	#dashboard-header-container {
		max-width: 100%;
		top: 5em;
	}
}
@media screen and (max-width: 700px) {
	.MuiPaper-root {
		width: 100% !important;
	}
	.css-uhb5lp {
		margin: 9px !important;
	}
}
