$color_1: #ffffff;
$color_2: var(--white);
$color_3: #949abd;
$font-family_1: "Sora", sans-serif;
$font-family_2: "Sora";
$background-color_1: transparent;


@import "./Variables.scss";

.footer-landing-container {
	margin-top: 5em;
	padding: 5.9em;

	.border-line {
		height: 2px !important;
		opacity: 1;
		border-top: none;
		background: linear-gradient(to right, #ab00c7 0%, #ab00c7 0.01%, #0075ff 100%);
	}

	* {
		line-height: 20px;
	}

	a {
		font-size: 14px;
		font-weight: 100;
	}

	p {
		font-size: 12px;
		font-weight: lighter;
	}

	#footer-text {
		padding-left: 14em;
		padding-right: 14em;

		@media screen and (max-width: 768px) {
			padding-left: 1em;
			padding-right: 1em;
		}
	}

	#footer-text-iva {
		text-align: center;
		margin: 0 auto;

		@media screen and (max-width: 768px) {
			text-align: left;
		}
	}


	#storm-logo-footer-landing {
		width: 138px;
		display: inline-block;

		@media screen and (max-width: 769px) {
			padding-left: 2.8em;
			transform: scale(3);
		}
	}
}

@media screen and (max-width: 768px) {
	.footer-landing-container {
		padding: 2em;
	}
}

.footer-section {
	position: relative;
	border-top: 1px solid white;
	padding-top: 20px;
	text-align: center;
	margin: auto 15px;
	margin-bottom: 4em;
	padding-bottom: 20px;

	a.footerEmail {
		font-family: $font-family_1;
		font-size: 14px;
		font-weight: 300;
		color: $color_1;
		outline: none;
		text-decoration: none;
		white-space: nowrap;
		text-decoration: underline;
	}
}

.footerEmail {
	color: white;

	&:hover {
		color: white
	}
}

.mail-us-footer-column {
	text-align: center;
}

.socialMediaWrapper {
	align-items: flex-start;
}

.socialMediaContainer {
	a {
		color: $color_2;
		font-family: $font-family_2;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 20px;
		text-decoration-line: underline;
		color: $color_1;

		&:hover {
			color: $color_2;
			font-family: $font-family_2;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 20px;
			text-decoration-line: underline;
			color: $color_1;
		}
	}
}

.footer-logo {
	width: 138px;
	margin-left: auto;
	margin-right: auto;
	display: inline-block;
}

.social-media {
	float: right;
	white-space: nowrap;

	a {
		margin: 0 0 0 30px;
	}
}

.footer-section-tokenomics {
	position: relative;
	top: 20em;
}

.footerDisclaimer {
	left: 0;
	position: relative;
	bottom: 0;
	top: 1em;
	background: black;
	width: 100%;
	font-family: $font-family_1;
	text-align: left;
	text-align: center;
	padding-left: 20%;
	padding-right: 20%;
	background-color: $background-color_1;

	p {
		font-weight: 700;
	}

	small {
		font-size: 12px;
		line-height: 15px;
		color: $color_3;
	}
}

.our-team {
	.footer-section {
		top: 0;
	}
}

@media screen and (max-width: 906px) {
	.footerDisclaimer {
		text-align: center;
		padding-left: 5%;
		padding-right: 5%;
	}
}

@media only screen and (max-width: 767px) {
	.social-media {
		float: unset !important;

		a {
			margin: 0 0 0 10px;
		}
	}

	.footer-section {
		a {
			float: unset !important;
		}
	}
}

@media screen and (max-width: 539px) {
	.social-media-img {
		padding: 4px;
	}

	.social-media-container {
		padding: initial;
	}

}
