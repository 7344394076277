$color_1: white;
$background-color_1: black;
$background-color_2: rgba(214, 97, 107, 0.5);

@import "./Variables.scss";
@keyframes pulsate {
	0% {
		opacity: 0.5;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0.5;
	}
}
.history-placements-container {
	height: 100%;
	min-height: 35em;
	font-size: 10px;
	padding: 0 30px 0 0;
	display: flex;
	flex-direction: column !important;
	justify-content: center;
	align-items: flex-start;
	position: relative;
}
.MuiTooltip-popperInteractive {
	border-radius: 16px;
}
.stats-placements-container {
	background-color: $background-color_1;
	border-radius: 30px;
	padding: 30px;
	.swiper-slide {
		width: 100% !important;
		height: auto !important;
	}
}
#swiper-stats {
	.swiper-button-next {
		&:after {
			display: none;
		}
	}
	.swiper-button-prev {
		&:after {
			display: none;
		}
	}
}
.stats-swiper {
	margin: 1em;
}
.hover-information-chart-container {
	height: 100%;
	background: var(--gradient-01);
	color: $color_1;
	padding: 1em;
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	span {
		margin: 0.3em 0 0.3em 0;
	}
	.leaderboard-date {
		justify-self: "center";
		margin: "0 1em 1em 1em";
	}
}
.leaderboard-live-container {
	.leaderboard-classic {
		margin: 0 !important;
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}
.history-placements-graph {
	height: 100%;
	width: 100%;
}
.history-placements-title {
	margin-bottom: 2em;
	padding-left: 6.3em;
	padding-right: 5px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	.text-h3 {
		white-space: unset;
	}
}
.ring-container {
	position: relative;
}
#dot {
	margin-left: 5px;
	width: 17px;
	height: 17px;
	border: 1px solid rgb(214, 97, 107);
	background-color: $background-color_2;
	border-radius: 100%;
	animation: pulsate 1.5s ease-out;
	animation-iteration-count: infinite;
	opacity: 0.5;
}
.dropdown-percentiles {
	min-width: 12em;
	width: fit-content;
}
.graphs-hover-and-dropdown {
	flex-grow: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
@media screen and (max-width: 899px) {
	.history-placements-title {
		flex-direction: row;
		padding: 0;
		.text-h3 {
			white-space: unset;
		}
	}
	.stats-placements-container {
		padding: 0;
		padding-top: 30px;
		padding-bottom: 30px;
		padding-right: 10px;
		padding-left: 10px;
	}
	.stats-container {
		padding-left: 1em !important;
		padding-right: 1em !important;
	}
	.history-placements-container {
		padding: 0;
	}
	.history-placements-graph {
		position: relative;
		right: 2em;
	}
	.graphs-hover-and-dropdown {
		flex-direction: column;
		flex-grow: unset;
	}
	.dropdown-percentiles {
		margin-top: 1em;
	}
}
