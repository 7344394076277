@keyframes slideInFromRight {
	0% {
		transform: translateX(100%) scale(0.4);
		opacity: 0;
	}

	100% {
		transform: translateX(0) scale(1);
		opacity: 1;
	}
}

@keyframes dissapearFromRight {
	0% {
		transform: translateX(0) scale(1);
		opacity: 1;
	}

	100% {
		transform: translateX(100%) scale(0.4);
		opacity: 0;
	}
}

.appear-from-right {
	opacity: 0;
	animation: slideInFromRight 2s forwards;
}

.disappear-from-right {
	opacity: 1;
	animation: dissapearFromRight 2s forwards;
}

@keyframes slideInFromBottom {
	0% {
		transform: translateY(100%) scale(0.4);
		opacity: 0;
	}

	100% {
		transform: translateX(0) scale(1);
		opacity: 1;
	}
}

@keyframes disapearFromBottom {
	0% {
		transform: translateY(0) scale(1);
		opacity: 1;
	}

	100% {
		transform: translateY(100%) scale(0.4);
		opacity: 0;
	}
}

.join-button {
	transform: translateY(100%) scale(0.4);
	opacity: 0;
}

.appear-from-bottom {
	opacity: 0;
	animation: slideInFromBottom 1s forwards;

	.join-button {
		opacity: 0;
		animation: slideInFromBottom 1s forwards;
		animation-delay: 0.9s;
	}

	.accordion-button-customize {
		opacity: 0;
		animation: slideInFromBottom 1s forwards;
		animation-delay: 0.4s;
	}
}

.disappear-from-bottom {
	opacity: 1;
	animation: disapearFromBottom 1s forwards;
}

.button-landing {
	border: 1px solid transparent;
	padding: 10px 22px;
	background: #ffffff;
	border-radius: 50px;
	color: black;
	transition: background 0.3s ease-out, color 0.3s ease-out, border 0.3s ease-out;

	&:hover {
		background: transparent;
		color: white;
		border: 1px solid white;
		transition: background 0.3s ease-out, color 0.3s ease-out, border 0.3s ease-out;
	}
}

.landing-container {
	background-color: black;
	background: black;
}

.landing-section {
	min-height: 110vh;
	min-height: 110dvh;
}

.vision-section {
	min-height: 100vh;
	min-height: 100dvh;
}

.sign-up-section {
	min-height: 100vh;
	min-height: 100dvh;
}

// .prevent-scrolling {
// 	overflow: hidden !important;
// }

#vison-estorm-title {
	transition: 1s all ease-out;
}

.gradient-text-conatainer {
	width: fit-content;
	background: linear-gradient(90deg, #ab00c7 0%, #0075ff 100.01%);
	-webkit-background-clip: text;
	background-clip: text;
}

.gradient-text {
	-webkit-text-fill-color: transparent;
	text-fill-color: transparent;
}