$color_1: purple;
$color_2: white;
$color_3: var(--white);
$background-color_1: rgba(0, 0, 0, 0.6);
$background-color_2: rgba(0, 0, 0, 0.9);
$background-color_3: rgba(0, 0, 0, 0);

@import "./Variables.scss";

@keyframes transparent-to-black {
	from {
		background-color: transparent;
	}

	to {
		background-color: rgba(0, 0, 0, 0.9);
	}
}

@keyframes black-to-transparent {
	from {
		background-color: rgba(0, 0, 0, 0.9);
	}

	to {
		background-color: rgba(0, 0, 0, 0);
	}
}

.toast-notification {
	a {
		text-decoration: none;
	}
}

.delete-all-text {
	white-space: nowrap;

	&:hover {
		cursor: pointer;
	}
}

.notifications-mobile {
	display: none !important;

	.box {
		width: 100%;
	}
}

.purple-color {
	path {
		color: $color_1;
	}
}

.notifications-icon {
	transform: scale(0.9);
}

.notification-popper {
	position: absolute;
	right: -1em;
	top: 5em;
	min-width: 30em;
	background-color: $background-color_1;
	border-radius: 30px;

	.notification-box-content {
		overflow: unset !important;
		flex-wrap: nowrap;
	}

	.notification-content-text {
		padding-right: 2em;
	}
}

.notification-popper-message {
	text-align: center;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
}

.notification-popper-icon {
	padding: 0 !important;
	padding-left: 0.5em !important;
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	align-self: stretch !important;
}

.cross-notification-pop-container {
	display: flex !important;
	align-items: center;
	justify-content: center;
	align-content: center;
	padding: 0 !important;
	margin: 0 !important;
	align-self: stretch !important;
	padding-right: 2em !important;
}

.cross-notifications-pop {
	opacity: 0.5;

	&:hover {
		opacity: 1;
		color: $color_2;
	}
}

.close-notification {
	align-self: stretch !important;
	justify-self: center;
	display: flex !important;
	justify-content: center;
	padding: 0 !important;
	padding-right: 0.5em !important;
	align-items: center;

	svg {
		margin: 0 !important;
	}
}

.notification-container {
	.MuiPaper-root {
		width: 100%;
		border-radius: 20px !important;
		border-top-right-radius: 0 !important;
		color: $color_3 !important;
		border: 0px;
	}

	background-color: $background-color_2;
	border-top-left-radius: 50%;
	border-top-right-radius: 50%;
}

.notification-header {
	.box {
		padding: 0;
		min-height: unset;
		min-width: 30em;
	}

	display: flex;
	align-items: center;
	position: relative;
	height: 59px;
	z-index: 2;

	&::before {
		content: "";
		position: absolute;
		height: 10px;
		width: 20px;
		bottom: 0;
		left: -20px;
		border-radius: 0 0 10px 0;
		-moz-border-radius: 0 0 10px 0;
		-webkit-border-radius: 0 0 10px 0;
		-webkit-box-shadow: 10px 0 0 0 rgba(0, 0, 0, 0.9);
		box-shadow: 10px 0 0 0 rgba(0, 0, 0, 0.9);
	}
}

.transparent-background {
	background-color: $background-color_3 !important;
	box-shadow: 10px 0 0 0 transparent !important;

	&::before {
		background-color: $background-color_3 !important;
		box-shadow: 10px 0 0 0 transparent !important;
	}
}

.notification-half-filler {
	position: absolute;
	bottom: 0;
	height: 50%;
	width: 100%;
	background-color: $background-color_2;
	z-index: -1;
}

.animation-transparent-black {
	animation: transparent-to-black 0.5s;
	animation-fill-mode: both;
}

.animation-black-transparent {
	animation: black-to-transparent 0.5s;
	animation-fill-mode: both;
}

.notification-dropdown {
	margin-top: 0.74em !important;
	margin-right: -1.3px !important;
}

@media only screen and (max-width: 899px) {
	.notification-desktop {
		display: none !important;
	}

	.notifications-mobile {
		display: flex !important;
	}

	#notification-pop {
		display: none;
	}
}