@import "../mixin";
:root {
	--diameter-circle: 140px;
}
.team-container {
	position: relative;
	display: flex;
	width: 100%;
	& #first-bubble-team-background {
		@include background_bubble(
			331.9px,
			331.9px,
			calc(50% - 186px) auto auto calc(50% - 191px),
			rotate(-1.77deg),
			linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%),
			0.3,
			blur(45px),
			0
		);
	}

	& #second-bubble-team-background {
		@include background_bubble(
			331.9px,
			331.9px,
			calc(50% - 116px) auto auto calc(50% - 175px),
			rotate(135deg),
			linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%),
			0.3,
			blur(45px),
			0
		);
	}

	& #third-bubble-team-background {
		@include background_bubble(
			331.9px,
			331.9px,
			calc(50% - 120px) auto auto calc(50% - 300px),
			rotate(135deg),
			linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%),
			0.3,
			blur(45px),
			0
		);
	}

	& #fourth-bubble-team-background {
		@include background_bubble(
			331.9px,
			331.9px,
			calc(50% - 110px) auto auto calc(50% - 310px),
			rotate(135deg),
			linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%),
			0.3,
			blur(45px),
			0
		);
	}
}

.team-circle-section {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: relative;
	width: 100%;
	min-height: 400px;
	z-index: 20;
}

.team-circle {
	width: 30%;
	filter: drop-shadow(-10px 14px 34px rgba(0, 0, 0, 0.75));
	aspect-ratio: 1/1;
	max-width: 180px;
	max-height: 180px;
	border-radius: 50%;
	cursor: pointer;
	position: absolute;
	transition: all 0.5s ease-out;
	transform: translate(-50%, -50%) translateZ(+20px);
	transform-origin: center center;

	img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		position: relative;
	}
	&:hover {
		z-index: 1000 !important;
		transform: translate(-50%, -50%) scale(1.1) translateZ(+50px) !important;

		&::after {
			box-shadow: unset !important;
		}
	}
	&::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		top: 0;
		left: 0;
		animation: pulse-aniamtion-team-memeber 2s infinite;
	}

	@media (max-width: 700px) {
		--diameter-circle: 100px;

		width: 105px;
		height: 106px;
	}
}
.team-circle:nth-child(1) {
	left: calc(50% - var(--diameter-circle) * cos(0deg));
	top: calc(50% - var(--diameter-circle) * sin(0deg));
	z-index: 3;
	&::after {
		animation-delay: 0s;
	}
}

.team-circle:nth-child(2) {
	left: calc(50% - var(--diameter-circle) * cos(60deg));
	top: calc(50% - var(--diameter-circle) * sin(60deg));
	z-index: 2;
	&::after {
		animation-delay: 0.2s;
	}
}

.team-circle:nth-child(3) {
	left: calc(50% - var(--diameter-circle) * cos(120deg));
	top: calc(50% - var(--diameter-circle) * sin(120deg));
	z-index: 3;
	&::after {
		animation-delay: 0.4s;
	}
}

.team-circle:nth-child(4) {
	left: calc(50% - var(--diameter-circle) * cos(180deg));
	top: calc(50% - var(--diameter-circle) * sin(180deg));
	z-index: 4;
	&::after {
		animation-delay: 0.6s;
	}
}

.team-circle:nth-child(5) {
	left: calc(50% - var(--diameter-circle) * cos(240deg));
	top: calc(50% - var(--diameter-circle) * sin(240deg));
	z-index: 5;
	&::after {
		animation-delay: 0.8s;
	}
}

.team-circle:nth-child(6) {
	left: calc(50% - var(--diameter-circle) * cos(300deg));
	top: calc(50% - var(--diameter-circle) * sin(300deg));
	z-index: 6;
	&::after {
		animation-delay: 1s;
	}
}

.team-text-section {
	display: flex;
	align-items: center;
	justify-content: center;
}

.team-text {
	font-size: 18px;
	text-align: center;
}
