$color_1: #fffefe61;
$font-family_1: "Sora";
$background-color_1: rgba(0, 0, 0, 0.2);
$background-color_2: transparent;
$background-color_3: black;
$background-color_4: unset;
$background-color_5: white;

@import "./Variables.scss";

@keyframes flick {
	0% {
		background-position: top left;
	}

	100% {
		background-position: top right;
	}
}

.market-main {
	position: relative;

	.react-tabs__tab-list {
		background-color: $background-color_3;
		border-radius: 16px;
		margin-bottom: 1em;
		float: left !important;
		margin-top: 7px;
	}

	.market-giftcard-selection {
		display: flex;
		height: 44px;
		float: right;
		margin-top: 7px;
	}

	.react-tabs__tab {
		margin: 4px !important;
		padding: 6px 12px !important;
	}

	.react-tabs__tab--selected {
		border: 0 !important;
		background: var(--gradient-01);
		border-radius: inherit;
	}
}

.subscription-card-bolt-sign {
	position: absolute;
	top: -7em;
	right: -10em;
	max-width: 400px;
	filter: invert(1);
	opacity: 0.3;
	overflow: hidden;
}

#freezed-bolts-amount {
	color: #007fff;
}

.market-subscription-card-info {
	position: relative;
	overflow: hidden;
	border-radius: 30px;
	display: flex;
	flex-direction: column !important;
	justify-content: space-between;
	height: 100%;
	padding: 1em;

	>* {
		>* {
			margin: 0;
			margin-bottom: 0.6em;
		}
	}

	div {
		&:nth-child(3) {
			margin-top: 2.25em;
		}
	}

	&::before {
		position: absolute;
		background: var(--gradient-01-30);
		width: 100%;
		height: 100%;
		content: "";
		border-radius: 30px;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		z-index: -1;
	}
}

.market-subscription-card {
	background-color: $background-color_1;
	border-radius: 30px;
	display: flex;
	flex-direction: column !important;
	justify-content: space-around;
	align-items: center;

	>* {
		width: 100%;
	}
}

.market-subcription-card-payment {
	display: flex;
	flex-direction: column;
	text-align: center;
	padding: 3em;
	padding-top: 0;
	padding-bottom: 0;

	>div {
		>* {
			margin-top: 0.7em;
		}
	}
}

.market-subscription-card-wrapper {
	display: flex;
	justify-content: center;

	a {
		text-decoration: none;
	}
}

.market-subscription-discount-info {
	display: "flex";
	align-items: "start";
	flex-direction: "column";
}

.market-payment-container {
	margin-top: 0.5em;
	height: 3em;
}

.market-payments-options {
	width: 100%;
	display: flex;
	flex-direction: column !important;
	justify-content: center;
}

.bolts-discount-container {
	display: flex;
	justify-content: space-between;
}

.bolts-discount-information {
	width: fit-content;
	background-color: $background-color_2;
	border: 0;
}

.bolts-discount-input {
	max-width: 70%;
}

.bolts-discount-slider {
	// max-width: 65%;
	// margin-left: 1em;
	height: 8px !important;
}

.market-giftcard-card-wrapper {
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column !important;
}

.market-giftcard-image {
	border-radius: 15px;
	width: 100%;
	height: 10em;
}

.market-giftcard-card-premium-badge {
	position: absolute;
	top: 25px;
	right: 10px;
	width: 70px;
	height: 22px;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	z-index: 4;
	background: var(--gradient-01);
	border-radius: 6px;

	span {
		font-family: $font-family_1;
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;
	}
}

.premium-giftcard-text {
	background: var(--gradient-01);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.countries-dropdown {
	min-width: 200px;
	display: flex;
}

.gift-card-checkout-description {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.gift-card-checkout-discount-detail {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.gift-card-checkout-discount-detail-container {
	width: 66%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.MuiSlider-track {
	background-color: $background-color_4;
	background: var(--gradient-01);
	border: unset;
}

.MuiSlider-thumb {
	background-color: $background-color_5 !important;
}

.work-in-progress {
	white-space: nowrap;
	margin-top: 2.5em;
	color: $color_1;
	background: -webkit-gradient(linear, left top, right top, from(#e6e6e6), to(#1d1c1c), color-stop(0.8, #ffffff)) no-repeat;
	background: gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.8, #fff)) no-repeat;
	background-size: 110px 100%;
	line-height: 90px;
	font-size: 80px;
	-webkit-background-clip: text;
	background-clip: text;
	animation: flick 1.5s infinite;
}

@media screen and (max-width: 800px) {
	.gift-card-checkout-discount-detail-container {
		width: 90%;
	}

	.market-subscription-card-wrapper {
		flex-direction: column !important;
	}

	.market-subcription-card-payment {
		padding: 1em;
	}
}

@media screen and (max-width: 645px) {
	.market-main {
		.market-giftcard-selection {
			float: left;
			margin-bottom: 1em;
		}

		.react-tabs__tab-list {
			margin-bottom: 0.5em;
		}
	}
}

@media screen and (max-width: 900px) {
	.work-in-progress {
		font-size: 50px;
	}
}

@media screen and (max-width: 600px) {
	.work-in-progress {
		margin-top: 4em;
		font-size: 32px;
	}
}