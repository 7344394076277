.take-part-in-our-story-container {
	// margin-top: 100px !important;
	// margin-bottom: 100px !important;

	@media (max-width: 700px) {
		// margin-top: -60px !important;
		// margin-bottom: 60px !important;
	}
}

.text-section-take-part-in-out-story {
	font-weight: 300 !important;
	font-size: 45px !important;
	line-height: 53px !important;
	text-align: left !important;
	opacity: 0.3;
	transition: opacity 0.5s ease-out;

	@media (max-width: 700px) {
		font-size: 25px !important;
		line-height: 32px !important;
	}

	&.highlighted {
		opacity: 1;
		transition: opacity 0.5s ease-out;
	}
}

.take-part-in-our-story-box {
	position: relative;
	text-align: center;
	min-height: 45vh;
	min-height: 45dvh;
	border-radius: 20px;
	// background: #d9d9d9;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 30px;
	flex-direction: column !important;

	@media (max-width: 700px) {
		border-radius: unset;
		min-height: 100vh;
		min-height: 100dvh;
	}

	.take-part-in-our-story-box-title {
		margin-top: 60px;
		font-weight: 400 !important;
		font-size: 68px !important;
		line-height: 86px !important;
		z-index: 12;
		white-space: nowrap;

		@media (max-width: 700px) {
			font-size: 32px !important;
			line-height: 47px !important;
		}
	}

	.take-part-in-our-story-box-button {
		padding: 18px 36px;

		background: white;
		border-radius: 50px;
		font-weight: 400;
		font-size: 25px;
		line-height: 32px;
		border: 0px;
		position: relative;
		color: black;

		&:hover {
			color: #7b00c7;
		}
	}
}

#gradient-canvas-take-part-in-our-story {
	--gradient-color-1: #659fe4;
	--gradient-color-2: #e69ff1;
	--gradient-color-3: #7b00c7;
	--gradient-color-4: #0075ff;
	z-index: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	border-radius: 20px;
}

#canvas-container-take-part-in-our-story {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	border-radius: 20px;

	.canvas-wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
		border-radius: 20px;
	}
}