@use "node_modules/react-widgets/scss/styles.scss" with ($input-height: 1em,
	// Most component variables are forwarded with a namespace
	$list-selected-bg: transparent,
	$widget-font-size: 14px,
	$input-padding-x: 0.5em);

.container-rewards-image {
	opacity: 0;
	width: 100%;
	display: flex;
	position: relative;
	justify-content: center;
	perspective: 1200px;
	/* Adjust the perspective value to control the depth effect */
	perspective-origin: center;

	& #rewards-first-bubble-background {
		position: absolute;
		width: 392.4px;
		height: 392.4px;
		left: 70%;
		bottom: 0%;

		/* grad01 */

		background: linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%);
		mix-blend-mode: screen;
		opacity: 0.4;
		filter: blur(29.5px);
		border-radius: 100000px;
		transform: rotate(88.23deg);
		z-index: -20;
		animation: bubble-rewards-first 5s ease-in-out infinite;

		@media (max-width: 700px) {
			width: 150px;
			height: 150px;
		}

		animation-delay: 0s;
	}

	& #rewards-second-bubble-background {
		position: absolute;
		width: 392.4px;
		height: 392.4px;
		left: 60%;
		top: 0%;
		z-index: -20;

		/* grad01 */

		background: linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%);
		mix-blend-mode: screen;
		opacity: 0.4;
		filter: blur(29.5px);
		border-radius: 100000px;
		transform: rotate(-135deg);
		animation: bubble-rewards-second 5s ease-in-out infinite;

		@media (max-width: 700px) {
			width: 150px;
			height: 150px;
		}

		animation-delay: 1s;
	}

	& #rewards-third-bubble-background {
		position: absolute;
		width: 392.4px;
		height: 392.4px;
		left: 25%;
		top: 10%;
		z-index: -20;

		/* grad01 */

		background: linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%);
		mix-blend-mode: screen;
		filter: blur(29.5px);
		border-radius: 100000px;
		transform: rotate(-135deg);
		animation: bubble-rewards-third 5s ease-in-out infinite;
		animation-delay: 2s;

		@media (max-width: 700px) {
			width: 150px;
			height: 150px;
		}
	}

	& #rewards-fourth-bubble-background {
		position: absolute;
		width: 392.4px;
		height: 392.4px;
		left: 10%;
		top: 0%;
		z-index: -20;

		/* grad01 */

		background: linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%);
		mix-blend-mode: screen;
		filter: blur(29.5px);
		border-radius: 100000px;
		transform: rotate(-135deg);
		animation: bubble-rewards-fourth 5s ease-in-out infinite;
		animation-delay: 3s;

		@media (max-width: 700px) {
			width: 150px;
			height: 150px;
		}
	}
}

@keyframes bubble-rewards-first {
	0% {
		transform: rotate(88.23deg) translateY(0) translateX(0) scale(1.5);
	}

	50% {
		transform: rotate(88.23deg) translateY(-100px) translateX(-100px) scale(0.7);
	}

	100% {
		transform: rotate(88.23deg) translateX(0) translateY(0) scale(1.5);
	}
}

@keyframes bubble-rewards-second {
	0% {
		transform: rotate(-135deg) translateY(0) translateX(0) scale(1.5);
	}

	50% {
		transform: rotate(-135deg) translateY(-100px) translateX(-100px) scale(0.7);
	}

	100% {
		transform: rotate(-135deg) translateX(0) translateY(0) scale(1.5);
	}
}

@keyframes bubble-rewards-third {
	0% {
		transform: rotate(-135deg) translateY(0) translateX(0) scale(1.5);
	}

	50% {
		transform: rotate(-135deg) translateY(-100px) translateX(-100px) scale(0.7);
	}

	100% {
		transform: rotate(-135deg) translateX(0) translateY(0) scale(1.5);
	}
}

@keyframes bubble-rewards-fourth {
	0% {
		transform: rotate(-135deg) translateY(0) translateX(0) scale(1.5);
	}

	50% {
		transform: rotate(-135deg) translateY(-100px) translateX(-100px) scale(0.7);
	}

	100% {
		transform: rotate(-135deg) translateX(0) translateY(0) scale(1.5);
	}
}

@keyframes slideInFromRight-rewards {
	0% {
		transform: translateX(100%) scale(0.4);
		opacity: 0;
	}

	100% {
		transform: translateX(0) scale(0.8);
		opacity: 1;
	}
}

@keyframes dissapearFromRigh-rewards {
	0% {
		transform: translateX(0) scale(0.8);
		opacity: 1;
	}

	100% {
		transform: translateX(100%) scale(0.4);
		opacity: 0;
	}
}

@keyframes slideInFromLeft-rewards {
	0% {
		transform: translateX(-100%) scale(0.4);
		opacity: 0;
	}

	100% {
		transform: translateX(0) scale(0.8);
		opacity: 1;
	}
}

@keyframes dissapearFromLeft-rewards {
	0% {
		transform: translateX(0) scale(0.8);
		opacity: 1;
	}

	100% {
		transform: translateX(-100%) scale(0.4);
		opacity: 0;
	}
}

.appear-from-right-rewards {
	opacity: 0;
	animation: slideInFromRight-rewards 2s forwards;
}

.disappear-from-right-rewards {
	opacity: 1;
	animation: dissapearFromRigh-rewards 2s forwards;
}

.appear-from-left-rewards {
	opacity: 0;
	animation: slideInFromLeft-rewards 2s forwards;
}

.disappear-from-left-rewards {
	opacity: 1;
	animation: dissapearFromLeft-rewards 2s forwards;
}

#rewards-text-container {
	z-index: 20;
	opacity: 0;
}

.container-rewards-call-to-action {
	margin-top: 36px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 24px;
	position: relative;

	@media (max-width: 700px) {
		gap: 12px;
		flex-direction: column;
	}

	@media (min-width: 700px) {
		>*:last-child {
			display: flex;
			overflow: hidden;
			max-width: 100%;
			flex-wrap: nowrap;

			position: relative;
			opacity: 0;
			transition: opacity 1s ease-out;
			align-self: flex-start;
		}

		&.accordion-text-open {
			flex-direction: column;
			align-items: flex-start;

			>*:last-child {
				max-width: 0 !important;
				opacity: 0 !important;
				transition: opacity 1s ease-out;
			}
		}
	}
}

.giftcard-button {
	flex: 20%;
	max-width: 100%;
	height: 60px;
	// aspect-ratio: 1.2/1;
	background: linear-gradient(90deg, #1b1751 0.58%, #2f0a4c 97.84%);
	border-radius: 10px;
	transition: 1s all ease-out;

	border: unset;
	font-family: "Sora";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	color: #ffffff;

	&:hover {
		background: linear-gradient(68.04deg, #ab00c7 -3.29%, #0075ff 99.93%);
		transition: 1s all ease-out;
	}

	&.amount-selected {
		background: linear-gradient(68.04deg, #ab00c7 -3.29%, #0075ff 99.93%);
		transition: 1s all ease-out;
	}
}

.rewards-image {
	transition: all 1s;

	width: 568px;
	transform: rotateY(20deg) rotateX(10deg) translateZ(0);

	/* Adjust the rotation and translation values to control the depth and angle */
	&:hover {
		transition: all 1s;
		transform: rotateY(calc(20deg + var(--mouse-x) / 80)) rotateX(calc(10deg - var(--mouse-y) / 40)) translateZ(0);
	}

	@media (max-width: 1500px) {
		width: 100%;
	}

	@media (max-width: 1200px) {
		width: 140%;
		max-width: 700px;
		flex-shrink: 0;
		margin-right: 40px;
	}

	@media (max-width: 850px) {
		margin-right: 120px;

		width: 110%;
	}

	@media (max-width: 700px) {
		margin-top: 50px;
		max-width: unset;
		margin-right: unset;

		position: relative;
		right: -5%;
		width: 110%;
	}
}

#giftcard-image {

	// transform: scale(0.8);
	&.interactable {
		position: relative;
		left: 0;
		width: 100% !important;
		background: rgba($color: #000000, $alpha: 0.7);
		border-radius: 30px;

		.market-giftcard-selection-rewards {
			display: flex;
			height: 44px;
			float: right;
			margin-top: 7px;

			.countries-dropdown-rewards {
				min-width: 200px;
				display: flex;

				.rw-widget-input {
					border: 0 !important;
					border-radius: 16px;
					background-color: rgba(0, 0, 0, 0.7) !important;
					opacity: 0.7 !important;
				}
			}

			.rw-list-option {
				padding-top: 0em !important;
				padding-bottom: 0em !important;
			}

			.rw-widget-input {
				background-color: rgba(0, 0, 0, 0.7) !important;
				box-shadow: unset !important;
				height: 100%;
				height: -webkit-fill-available;
			}

			.rw-open {
				background-clip: padding-box;
				border: 2px solid transparent;

				background-color: rgba(0, 0, 0, 0.7) !important;

				border-radius: 16px;

				.rw-widget-input {
					border: 0;
				}
			}

			.rw-popup {
				background-color: rgba(0, 0, 0, 0.7) !important;
				color: white;
			}

			.rw-list-option {
				color: white;
			}

			.rw-list-option:hover {
				background: linear-gradient(90deg, #ab00c7 0.59%, #0075ff 97.84%);
				opacity: 0.5;
			}

			.rw-state-focus {
				background-clip: padding-box;
				border: 2px solid transparent;

				background-color: rgba(0, 0, 0, 0.7) !important;

				border-radius: 16px;

				.rw-widget-input {
					border: 0;
				}
			}

			.rw-dropdown-list-value {
				color: white;
			}
		}

		@keyframes purchase-animation {
			0% {
				transform: rotateY(20deg) rotateX(10deg) translateZ(0px);
			}

			100% {
				transform: rotateY(20deg +360deg) rotateX(10deg) translateZ(0px);
			}
		}

		&.purchase-animation {
			animation: purchase-animation 2s forwards;
		}
	}

	#amazon-image {
		aspect-ratio: 1 / 1;
		width: 100%;
		height: 100%;
		height: -webkit-fill-available;
	}
}

.button-pay-giftcard {
	border: 1px solid transparent;
	padding: 8px 18px;
	margin: 3px;
	background: #ffffff;
	border-radius: 50px;
	color: black;
	transition: background 0.3s ease-out, color 0.3s ease-out, border 0.3s ease-out;

	&:hover {
		background: transparent;
		color: white;
		border: 1px solid white;
		transition: background 0.3s ease-out, color 0.3s ease-out, border 0.3s ease-out;
	}
}