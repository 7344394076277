.fomo-title {
	font-size: 35px !important;
	line-height: 44px !important;
	@media (max-width: 700px) {
		font-size: 20px !important;
		line-height: 25px !important;
	}
}

.fomo-text {
	font-weight: 300 !important;
	font-size: 35px !important;
	line-height: 44px !important;
	@media (max-width: 700px) {
		font-size: 20px !important;
		line-height: 25px !important;
	}
}
