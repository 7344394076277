@import "../mixin";
.bolts-rain-container {
	position: relative;
	width: 100%;
	z-index: 10;
	@media (max-width: 700px) {
		position: absolute;
	}
	& .bolts-sign {
		position: absolute;
		width: 170px;
		height: 170px;
		border-radius: 50%;

		@media (max-width: 700px) {
			width: 123.58px;
			height: 123.58px;
			z-index: -1;
		}
		img {
			width: 84.24px;
			height: 84.24px;
			border-radius: 50%;
			@media (max-width: 700px) {
				width: 61.24px;
				height: 61.24px;
			}
		}
		.bolts-sign-container {
			border-radius: 50%;

			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			width: 100%;
			position: relative;
			opacity: 0;
			animation: bolts-animation-out calc(1s + var(--bolts-delay)) forwards;
			// animation-delay: var(--bolts-delay);
			transform: translateY(0) rotate(var(--bolts-rotation));
			@media (max-width: 900px) {
				animation: bolts-animation-out-mobile calc(1s + var(--bolts-delay)) forwards;
				opacity: 0.3;
			}
		}
	}
	#bolts-sign-1 {
		left: calc(50% - 50px);
		top: calc(50% - 200px);
		transform: translate(-50%, -50%);
		.bolts-sign-container {
			background: linear-gradient(221.88deg, rgba(217, 217, 217, 0.46) 13.19%, rgba(217, 217, 217, 0) 97.26%);
			// @media (max-width: 700px) {
			// 	// background: linear-gradient(221.88deg, rgba(217, 217, 217, 0.23) 13.19%, rgba(217, 217, 217, 0) 97.26%);
			// 	opacity: 0.3;
			// }
		}
	}
	#bolts-sign-2 {
		left: calc(50% + 90px);
		top: calc(50% - 100px);
		transform: translate(-50%, -50%);
		.bolts-sign-container {
			background: linear-gradient(221.88deg, rgba(217, 217, 217, 0.46) 13.19%, rgba(217, 217, 217, 0) 97.26%);
		}
	}
	#bolts-sign-3 {
		left: calc(50% - 60px);
		top: calc(50%);
		transform: translate(-50%, -50%) rotate(var(--bolts-rotation));
		.bolts-sign-container {
			background: linear-gradient(221.88deg, rgba(217, 217, 217, 0.7) 13.19%, rgba(217, 217, 217, 0) 97.26%);
		}
	}
	#bolts-sign-4 {
		left: calc(50% + 80px);
		top: calc(50% + 80px);
		transform: translate(-50%, -50%) rotate(var(--bolts-rotation));
		.bolts-sign-container {
			background: linear-gradient(221.88deg, rgba(217, 217, 217, 0.16) 13.19%, rgba(217, 217, 217, 0) 97.26%);
		}
	}
	#bolts-sign-5 {
		left: calc(50% + 180px);
		top: calc(50% + 180px);
		transform: translate(-50%, -50%);
		.bolts-sign-container {
			background: linear-gradient(221.88deg, rgba(217, 217, 217, 0.16) 13.19%, rgba(217, 217, 217, 0) 97.26%);
		}
	}
	&.bolts-animation {
		.bolts-sign-container {
			opacity: 1;
			transform: translateY(0) rotate(var(--bolts-rotation));
			animation: bolts-animation calc(1s + var(--bolts-delay)) ease-out;
			@media (max-width: 900px) {
				opacity: 0.3;
				animation: bolts-animation-mobile calc(1s + var(--bolts-delay)) forwards;
			}
		}
	}
	& #first-bubble-bolts-rain-background {
		@include background_bubble(
			484.7px,
			484.7px,
			calc(50%) auto auto calc(50% - 300px),
			rotate(88.23deg),
			linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%),
			0.14,
			blur(29.5px),
			0
		);
		@media (max-width: 900px) {
			display: none;
		}
	}

	& #second-bubble-bolts-rain-background {
		@include background_bubble(
			484.7px,
			484.7px,
			calc(50% - 450px) auto auto calc(50% - 220px),
			rotate(-135deg),
			linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%),
			0.14,
			blur(29.5px),
			0
		);
		@media (max-width: 900px) {
			display: none;
		}
	}

	& #third-bubble-bolts-rain-background {
		@include background_bubble(
			484.7px,
			484.7px,
			calc(50% - 200px) auto auto calc(50% - 350px),
			rotate(-135deg),
			linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%),
			0.14,
			blur(29.5px),
			0
		);
		@media (max-width: 900px) {
			display: none;
		}
	}

	& #fourth-bubble-bolts-rain-background {
		@include background_bubble(
			484.7px,
			484.7px,
			calc(50% - 350px) auto auto calc(50% - 350px),
			rotate(-135deg),
			linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%),
			0.14,
			blur(29.5px),
			0
		);
		@media (max-width: 900px) {
			display: none;
		}
	}
}
@keyframes bolts-animation {
	0% {
		transform: translateY(calc(300% * cos(var(--bolts-rotation)))) translateX(calc(300% * sin(var(--bolts-rotation))))
			rotate(var(--bolts-rotation));
		opacity: 0;
	}
	100% {
		transform: translateY(0) rotate(var(--bolts-rotation));
		opacity: 1;
	}
}
@keyframes bolts-animation-out {
	0% {
		transform: translateY(0) rotate(var(--bolts-rotation));
		opacity: 1;
	}
	100% {
		transform: translateY(calc(-300% * cos(var(--bolts-rotation)))) translateX(calc(-300% * sin(var(--bolts-rotation))))
			rotate(var(--bolts-rotation));
		opacity: 0;
	}
}

@keyframes bolts-animation-mobile {
	0% {
		transform: translateY(calc(300% * cos(var(--bolts-rotation)))) translateX(calc(300% * sin(var(--bolts-rotation))))
			rotate(var(--bolts-rotation));
		opacity: 0;
	}
	100% {
		transform: translateY(0) rotate(var(--bolts-rotation));
		opacity: 0.3;
	}
}
@keyframes bolts-animation-out-mobile {
	0% {
		transform: translateY(0) rotate(var(--bolts-rotation));
		opacity: 0.3;
	}
	100% {
		transform: translateY(calc(-300% * cos(var(--bolts-rotation)))) translateX(calc(-300% * sin(var(--bolts-rotation))))
			rotate(var(--bolts-rotation));
		opacity: 0;
	}
}

.how-to-earn-text-first-section {
	opacity: 0;
	transition: opacity 1s ease-out;
	@media (max-width: 700px) {
		text-align: left;
	}
}
.how-to-earn-text-second-section {
	opacity: 0;
	transition: opacity 1s ease-out;
	transition-delay: 0.25s;
	@media (max-width: 700px) {
		text-align: left;
	}
}
#how-to-earn-text-container {
	&.bolts-animation {
		.how-to-earn-text-first-section {
			opacity: 1;
			transition: opacity 1.5s ease-out;
		}
		.how-to-earn-text-second-section {
			opacity: 1;
			transition: opacity 1.5s ease-out;
			transition-delay: 0.5s;
		}
	}
}

#about-us-image-container {
	opacity: 0;
	width: 100%;
	display: flex;
	position: relative;
	right: -250px;
	justify-content: center;
	perspective: 1200px; /* Adjust the perspective value to control the depth effect */
	perspective-origin: center;
	@media (max-width: 700px) {
		right: 10px;
	}
	& #first-bubble-background {
		@include background_bubble(
			392.4px,
			392.4px,
			10% auto auto 0%,
			rotate(88.23deg) linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%),
			0.4,
			blur(29.5px),
			-20,
			bubble 2s ease-in-out infinite,
			0s
		);
		@media (max-width: 700px) {
			left: -10%;
			width: 200px;
			height: 200px;
			animation: bubble-mobile 2s ease-in-out infinite;
			animation-delay: 0s;
		}
	}
	& #second-bubble-background {
		@include background_bubble(
			392.4px,
			392.4px,
			5% 0% auto auto,
			rotate(-135deg),
			linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%),
			0.4,
			blur(29.5px),
			-20,
			bubble 2s ease-in-out infinite,
			1s
		);

		@media (max-width: 700px) {
			right: -10%;
			width: 200px;
			height: 200px;
			animation: bubble-mobile 2s ease-in-out infinite;
			animation-delay: 1s;
		}
	}
	& #third-bubble-background {
		@include background_bubble(
			392.4px,
			392.4px,
			15% auto auto 25%,

			rotate(-135deg),
			linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%),
			0.4,
			blur(29.5px),
			-20,
			bubble 2s ease-in-out infinite,
			2s
		);

		@media (max-width: 700px) {
			width: 200px;
			height: 200px;
			animation: bubble-mobile 2s ease-in-out infinite;
			animation-delay: 2s;
		}
	}
	& #fourth-bubble-background {
		@include background_bubble(
			392.4px,
			392.4px,
			4% 5% auto auto,
			rotate(-135deg),
			linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%),
			0.4,
			blur(29.5px),
			-20,
			bubble 2s ease-in-out infinite,
			3s
		);

		@media (max-width: 700px) {
			right: 0;
			width: 200px;
			height: 200px;
			animation: bubble 2s ease-in-out infinite;
			animation-delay: 3s;
		}
	}
}
@keyframes bubble {
	0% {
		transform: translateY(0);
		opacity: 1;
	}
	100% {
		transform: translateY(-200px);
		opacity: 0;
	}
}
@keyframes bubble-mobile {
	0% {
		transform: translateY(50px);
		opacity: 0.6;
	}
	100% {
		transform: translateY(-100px);
		opacity: 0;
	}
}
.leaderboard-image-about-us {
	transition: all 1s;

	width: 130%;
	transform: rotateY(-25deg) rotateX(10deg) translateZ(+100px); /* Adjust the rotation and translation values to control the depth and angle */
	&:hover {
		transition: all 1s;
		transform: rotateY(calc(-25deg + var(--mouse-x) / 80)) rotateX(calc(10deg - var(--mouse-y) / 40)) translateZ(+100px);
	}
	@media (max-width: 1500px) {
		// width: 400px;
	}

	@media (max-width: 1200px) {
		width: 100%;
		max-width: 400px;
		margin-left: 40px;
	}

	@media (max-width: 850px) {
		margin-left: 120px;

		width: 110%;
	}
	@media (max-width: 700px) {
		margin-top: 50px;
		max-width: unset;
		margin-left: unset;

		position: relative;
		right: -15%;
		width: 100%;
	}
}
.text-section-how-to-earn {
	text-align: left;
	opacity: 0.3;
	transition: opacity 0.5s ease-out;
}
.highlighted {
	opacity: 1;
	transition: opacity 0.5s ease-out;
}
