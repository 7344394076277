@import "./Variables.scss";

.header-container {
	display: flex;
	justify-content: center;
}

.header {
	top: 0;
	z-index: 21000 !important;
	text-align: center;
	width: 100%;
	position: absolute !important;
	padding: 0;
	/* color: white; */
	width: 95%;
}

.header .navbar-collapse {
	justify-content: flex-end;
}

.header .navbar-collapse .select-container {
	margin: 0;
}

/*Header-menu Logo*/
.header .col-header-logo {
	padding-left: 12px;
	opacity: 1;
	transition: opacity 0.2s;
	display: flex;
	align-content: center;
	justify-content: center;
}

.col-header-logo {
	cursor: pointer;
}

.open-menu-logo {
	display: block;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	transition: background-image 0.4s ease-in;
}

#logo-navbar-collapse {
	display: none;
}

#logo-navbar {
	display: inline;
	padding-left: 2rem;
}

.navbar-icon {
	text-align: right;
}

.navbar-icon-container {
	width: "100%";
	display: "flex";
	align-items: "center";
	justify-content: "center";
}

@media screen and (max-width: 1100px) {
	#logo-navbar {
		display: none;
	}
}

@media screen and (max-width: 899px) {
	.navbar-content {
		display: grid;
		grid-template-columns: 1fr 1fr;
		justify-content: space-between;
		padding: 1em;
		background: black;
	}

	#logo-navbar-collapse {
		display: inline;
	}

	#logo-navbar {
		display: none;
	}
}

@supports not (aspect-ratio: 16 / 9) {
	.container-element-no-drop #header-logo {
		padding-top: 17.7%;
	}
}

@supports (aspect-ratio: 16 / 9) {
	.container-element-no-drop #header-logo {
		aspect-ratio: 242/43;
	}
}

#header-logo {
	display: block;
	width: 138px;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
}

.container-element-no-drop #header-logo {
	display: block;
	background-size: 100% 100%;
	width: 138px;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
}

/*Menu Icon*/
.navbar-dark .collapsed .navbar-toggler-icon {
	background-image: url("https://storage.googleapis.com/images_gifs_videos_storm/images/MobileMenuButton.png") !important;
}

.navbar-dark .navbar-toggler-icon {
	background-image: url("https://storage.googleapis.com/images_gifs_videos_storm/images/closeMenuBotton.png") !important;
}

.navbar-dark .navbar-toggler {
	border: 0 !important;
}

.navbar-toggler:focus {
	box-shadow: 0 0 0 0 !important;
}

/*Navbar elements desktop */
.container-element-no-drop {
	display: flex;
	height: fit-content;
	flex-direction: row;
	justify-content: space-between;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
	padding: 1rem;
	padding-bottom: 2em;
	background-color: transparent;
}

.header .navbar-container {
	margin: 0 !important;
	padding: 0 !important;
	max-width: 100% !important;
	border-radius: 0;
}

/* Animetion dropdown menu */
@-webkit-keyframes animation {
	0% {
		background-color: transparent;
	}

	100.0% {
		background-color: black;
	}
}

@keyframes animation {
	0% {
		background-color: transparent;
	}

	100.0% {
		background-color: black;
	}
}

.open-menu {
	-webkit-animation-name: animation;
	-webkit-animation-duration: 0.4s;
	-webkit-animation-timing-function: ease-in-out;
	-webkit-animation-play-state: running;

	animation-name: animation;
	animation-duration: 0.4s;
	animation-timing-function: ease-in-out;
	animation-play-state: running;
	background-color: black;
}

.close-menu {
	background-color: transparent !important;
	transition: all 0.4s ease-out !important;
}

.header-menu-open {
	position: fixed !important;
}

.header-menu-close {
	position: absolute !important;
}

.stop-scrolling {
	overflow: hidden;
}

.stop-scrolling:hover {
	overflow: auto;
}

/*Dropdown elements*/
.container-dropdown {
	justify-content: flex-start;
}

.header-dropdown-first {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.header-dropdown-second {
	display: none;
}

.header a.btn-login {
	padding-left: 2rem;
	padding-right: 2rem;
}

/*Footer menu*/
.menu-footer {
	display: none;
}

.menu-footer .select-list-item {
	background: rgba(0, 0, 0, 0.5);
}

.menu-footer .select-list {
	position: relative;
	top: 0;
	transition: 0.5s ease-out;
}

.navbar-footer-mobile {
	display: none;
	position: fixed;
	bottom: 0;
	background-color: black;
	border-radius: 20px 20px 0px 0px;
	padding: 0.5em;
}

.navbar-header-mobile {
	display: none;
	position: relative;
	top: 0;
	background-color: black;
}

.navbar-header-mobile>div {
	text-align: center;
	width: 100%;
	justify-content: center;
}

.navbar-footer-mobile>a {
	text-align: center;
	width: 100%;
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: center;
	color: white;
}

.navbar-header-mobile .text-cta img {
	// margin-top: 2px;
	margin-right: 0.3em;
}

.navbar-header-mobile .text-cta {
	font-family: "Sora";
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 30px;
	margin: auto;
	display: flex;
	align-content: center;
	justify-content: center;
	align-items: center;
}

.page-selected {
	opacity: 1 !important;
}

.nav-link-gamehub,
.nav-link-home,
.nav-link-settings,
.nav-link-statsDashboard,
.nav-link-market {
	opacity: 0.5;
}

.notification-mobile-swipe .MuiPaper-root {
	width: 100% !important;
	border-radius: 0 !important;
	background-color: rgba(0, 0, 0, 1) !important;
	border: 0 !important;
}

.close-swipeabledrawer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.close-swipeabledrawer-icon {
	margin: 1rem;
	margin-right: 2rem;
}

.navbar-icon {
	max-width: 33px;
}

@media only screen and (max-width: 899.9px) {
	.navbar-header-desktop {
		display: none !important;
	}

	#dashboard-header-container {
		display: none;
	}

	.navbar-footer-mobile {
		display: flex;
		flex-direction: row;

		width: 100%;
		z-index: 102;
	}

	.navbar-header-mobile {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
		padding-top: 1em;
		padding-bottom: 1.5em;
		flex-grow: 2;
		width: 100%;
		z-index: 102;
	}
}

@media only screen and (min-width: 900px) {
	.header .navbar-collapse a {
		margin-right: 1rem;
	}

	.header .link-header {
		padding: 7px 15px;
	}
}

@media only screen and (min-width: 767.5px) {
	.header {
		position: fixed !important;
	}

	.header .navbar-collapse a {
		margin-right: 0;
	}

	.header .footer-section {
		display: none;
	}
}

@media only screen and (max-width: 767.5px) {
	.container-element-no-drop {
		background: transparent;
		width: 100%;
	}

	.container-dropdown {
		height: calc(100vh - 83px);
		overflow: auto;
	}

	.container-dropdown:hover {
		overflow-y: auto;
	}

	.header-dropdown-first {
		padding-top: 5rem;
		flex-direction: column;
		justify-content: flex-start;

		border-top-right-radius: 30px;
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
		border-bottom-right-radius: 30px;
	}

	.header-dropdown-first .link-header {
		margin-bottom: 2.5rem;
	}

	.header a.btn-login {
		margin-left: auto;
		margin-right: auto;
		padding-left: 5rem;
		padding-right: 5rem;
		margin-bottom: 5rem;
	}

	.header-dropdown-second {
		display: flex;
		border-radius: 30px;
		margin-top: 1rem;
	}

	.menu-footer {
		width: 100%;
		padding-top: 2rem;
		padding-bottom: 2rem;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}

	.menu-footer .menu-footer-icon {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.menu-footer-icon a {
		margin-right: 1rem;
	}

	.header-language-desktop {
		display: none;
	}
}

@media only screen and (max-width: 480px) {
	.header {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}
}

@media (min-width: 900px) {
	.navbar-expand-lg {
		-ms-flex-flow: row nowrap !important;
		flex-flow: row nowrap !important;
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}

	.navbar-expand-lg .navbar-nav {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
		align-items: center !important;
	}

	.navbar-expand-lg .navbar-nav .dropdown-menu {
		position: absolute !important;
	}

	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}

	.navbar-expand-lg>.container,
	.navbar-expand-lg>.container-fluid {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}

	.navbar-expand-lg .navbar-collapse {
		display: -ms-flexbox !important;
		display: flex !important;
		-ms-flex-preferred-size: auto !important;
		flex-basis: auto !important;
	}

	.navbar-expand-lg .navbar-toggler {
		display: none !important;
	}
}