#vision-container {
	background: linear-gradient(90deg, rgba(171, 0, 199, 0.15) 0%, rgba(0, 117, 255, 0.15) 100.01%);
	position: relative;
	overflow: hidden;
}
#gradient-canvas-hero {
	width: 0px;
	// height: 0px;
	--gradient-color-1: #659fe4;
	--gradient-color-2: #e69ff1;
	--gradient-color-3: #7b00c7;
	--gradient-color-4: #0075ff;
	z-index: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	border-radius: 50%;
}
.hero-cut {
	width: 100%;
	aspect-ratio: 160/23;
	position: absolute;
	z-index: 120;
	bottom: 0;
	left: 0;
	@media (max-width: 700px) {
		display: none;
	}
}
.hero-button {
	background: #000000;
	border-radius: 50px;
	padding: 10px 22px;
	color: white;
	transition: all 1s ease-out;
	&:hover {
		color: black;
		background: white;
		transition: all 1s ease-out;
	}
}
@keyframes progress {
	0% {
		width: 0px;
	}
	100% {
		width: 100%;
	}
}
@keyframes progress-mobile {
	0% {
		height: 0px;
	}
	100% {
		height: 100%;
	}
}

#canvas-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	aspect-ratio: 1/1;
	border-radius: 50%;
	@media (orientation: portrait) {
		width: unset;
		height: 100%;
	}

	.canvas-wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	&.hero-canvas-animaiton {
		animation-name: progress;
		animation-duration: 2s;
		animation-timing-function: linear;
		animation-fill-mode: forwards;
		@media (orientation: portrait) {
			animation-name: progress-mobile;
		}
		#gradient-canvas-hero {
			border-radius: 0%;
			width: 100%;
			aspect-ratio: 1/1;
			transition: all 2s linear, border-radius 1s ease-out 2s;
			@media (orientation: portrait) {
				width: unset;
				height: 100%;
			}
		}
	}
}
