.landing-container {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	a,
	button,
	span,
	label {
		font-family: "Sora";
		font-style: normal;
	}
	.vision-title {
		font-weight: 300;
		font-size: 60px;
		line-height: 76px;
		text-align: left;
		@media (max-width: 600px) {
			font-size: 34px;
			line-height: 52px;
			text-align: left;
		}
	}

	.accordion-text {
		font-weight: 300;
		font-size: 21px;
		line-height: 26px;
	}
	.section-text {
		font-weight: 300 !important;
		font-size: 50px !important;
		line-height: 58px !important;
		@media (max-width: 700px) {
			margin-top: 32px !important;
			font-size: 35px !important;
			line-height: 44px !important;
		}
	}
}
