@import "../mixin";
#first-bubble-founders-background {
	@include background_bubble(
		268.29px,
		268.29px,
		calc(50% - 422px) auto auto calc(50% - 252px),
		rotate(-138.38deg) translate(-50%, -50%),
		linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%),
		0.4,
		blur(15px),
		0
	);
}

#second-bubble-founders-background {
	@include background_bubble(
		268.29px,
		268.29px,
		calc(50% + 50px) auto auto calc(50% - 55px),
		rotate(-1.61deg) translate(-50%, -50%),
		linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%),
		0.4,
		blur(15px),
		0
	);
}

#third-bubble-founders-background {
	@include background_bubble(
		268.29px,
		268.29px,
		calc(50% - 43px) auto auto calc(50% - 15px),
		rotate(-1.61deg) translate(-50%, -50%),
		linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%),
		0.4,
		blur(15px),
		0
	);
}

#fourth-bubble-founders-background {
	@include background_bubble(
		268.29px,
		268.29px,
		calc(50%) auto auto calc(50%),
		rotate(-1.61deg) translate(-50%, -50%),
		linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%),
		0.4,
		blur(15px),
		0
	);
}
#ruben-soro-founder {
	position: absolute;
	top: calc(50% - 15px);
	left: calc(50% - 90px);
	transform: translate(-50%, -50%);

	width: 223px;

	height: 224px;
	filter: drop-shadow(-10px 14px 34px rgba(0, 0, 0, 0.75));
	@media (max-width: 700px) {
		left: calc(50% - 50px);
		width: 148px;
		height: 148px;
	}
}

#alessio-di-dio-founder {
	position: absolute;
	top: calc(50% + 15px);
	left: calc(50% + 90px);
	transform: translate(-50%, -50%);

	width: 223px;
	height: 224px;
	// animation: rotation-alessio 2s infinite linear;
	filter: drop-shadow(-10px 14px 34px rgba(0, 0, 0, 0.75));
	@media (max-width: 700px) {
		left: calc(50% + 50px);

		width: 148px;
		height: 148px;
	}
}
// @keyframes rotation-alessio {
// 	0% {
// 		transform: translate(-50%, -50%) rotate(0deg);
// 	}
// 	100% {
// 		transform: translate(-50%, -50%) rotate(360deg);
// 	}
// }
.founders-container {
	min-height: 50vh;
	min-height: 50dvh;
	& .founder-images {
		min-height: 400px;
		@media (max-width: 700px) {
			min-height: 300px;
		}
		& .team-member-image {
			transition: all 0.5s ease-out;
			cursor: pointer;
			img {
				width: 100%;
				height: 100%;
				position: relative;
				border-radius: 50%;
				transition: all 0.5s ease-out;
			}
			&:hover {
				z-index: 100;
				transform: translate(-50%, -50%) scale(1.1) translateZ(+50px) !important;

				&::after {
					animation: none;
				}
			}

			&::after {
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				border-radius: 50%;
				top: 0;
				left: 0;
				animation: pulse-aniamtion-team-memeber 2s infinite;
			}
		}
	}
}
@keyframes pulse-aniamtion-team-memeber {
	0% {
		box-shadow: 0 0 0 0px rgba(171, 0, 199, 0.7), 0 0 0 0px rgba(0, 117, 255, 0.3);
	}
	50% {
		box-shadow: 0 0 0 9.5px rgba(0, 0, 0, 0), 0 0 0 20px rgba(0, 0, 0, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
.story-telling-text {
	text-align: center;
	font-weight: 300 !important;
	font-size: 32px !important;
	line-height: 42px !important;
	@media (max-width: 700px) {
		font-size: 25px !important;
		line-height: 32px !important;
	}
}
.text-section {
	opacity: 0.3;
	transition: opacity 0.5s ease-out;
}
.highlighted {
	opacity: 1;
	transition: opacity 0.5s ease-out;
}
