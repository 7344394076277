.container-how-image {
	opacity: 0;
	width: 100%;
	display: flex;
	position: relative;
	justify-content: center;
	perspective: 1200px; /* Adjust the perspective value to control the depth effect */
	perspective-origin: center;
	& #first-bubble-background {
		position: absolute;
		z-index: -1;
		width: 392.4px;
		height: 392.4px;
		left: 0%;
		top: 10%;

		/* grad01 */

		background: linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%);
		mix-blend-mode: screen;
		opacity: 0.4;
		filter: blur(29.5px);
		border-radius: 100000px;
		transform: rotate(88.23deg);
		z-index: -20;
		animation: bubble 2s ease-in-out infinite;
		animation-delay: 0s;
		@media (max-width: 700px) {
			left: -10%;
			width: 200px;
			height: 200px;
			animation: bubble-mobile 2s ease-in-out infinite;
			animation-delay: 0s;
		}
	}
	& #second-bubble-background {
		position: absolute;
		z-index: -1;
		width: 392.4px;
		height: 392.4px;
		right: 0%;
		top: 5%;
		z-index: -20;

		/* grad01 */

		background: linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%);
		mix-blend-mode: screen;
		opacity: 0.4;
		filter: blur(29.5px);
		border-radius: 100000px;
		transform: rotate(-135deg);
		animation: bubble 2s ease-in-out infinite;
		animation-delay: 1s;
		@media (max-width: 700px) {
			right: -10%;
			width: 200px;
			height: 200px;
			animation: bubble-mobile 2s ease-in-out infinite;
			animation-delay: 1s;
		}
	}
	& #third-bubble-background {
		position: absolute;
		z-index: -1;
		width: 392.4px;
		height: 392.4px;
		left: 25%;
		top: 15%;
		z-index: -20;

		/* grad01 */

		background: linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%);
		mix-blend-mode: screen;
		filter: blur(29.5px);
		border-radius: 100000px;
		transform: rotate(-135deg);
		animation: bubble 2s ease-in-out infinite;
		animation-delay: 2s;
		@media (max-width: 700px) {
			width: 200px;
			height: 200px;
			animation: bubble-mobile 2s ease-in-out infinite;
			animation-delay: 2s;
		}
	}
	& #fourth-bubble-background {
		position: absolute;
		z-index: -1;
		width: 392.4px;
		height: 392.4px;
		right: 5%;
		top: 4%;
		z-index: -20;

		/* grad01 */

		background: linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%);
		mix-blend-mode: screen;
		filter: blur(29.5px);
		border-radius: 100000px;
		transform: rotate(-135deg);
		animation: bubble 2s ease-in-out infinite;
		animation-delay: 3s;
		@media (max-width: 700px) {
			right: 0;
			width: 200px;
			height: 200px;
			animation: bubble-mobile 2s ease-in-out infinite;
			animation-delay: 3s;
		}
	}
}
@keyframes bubble {
	0% {
		transform: translateY(0);
		opacity: 1;
	}
	100% {
		transform: translateY(-200px);
		opacity: 0;
	}
}
@keyframes bubble-mobile {
	0% {
		transform: translateY(50px);
		opacity: 0.6;
	}
	100% {
		transform: translateY(-100px);
		opacity: 0;
	}
}
#how-text-container {
	opacity: 0;
	z-index: 10;
}
.container-how-call-to-action {
	// position: absolute;
	margin-top: 36px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 24px;
	position: relative;

	@media (max-width: 700px) {
		gap: 12px;
		flex-direction: column;
	}
	@media (min-width: 700px) {
		> *:last-child {
			display: flex;
			overflow: hidden;
			max-width: 100%;
			flex-wrap: nowrap;

			position: relative;
			opacity: 0;
			transition: opacity 1s ease-out;
			align-self: flex-start;
		}
		&.accordion-text-open {
			flex-direction: column;
			align-items: flex-start;

			> *:last-child {
				max-width: 0 !important;
				opacity: 0 !important;
				transition: opacity 1s ease-out;
			}
		}
	}
}
.leaderboard-image {
	transition: all 1s;

	width: 568px;
	transform: rotateY(-25deg) rotateX(10deg) translateZ(+100px); /* Adjust the rotation and translation values to control the depth and angle */
	&:hover {
		transition: all 1s;
		transform: rotateY(calc(-25deg + var(--mouse-x) / 80)) rotateX(calc(10deg - var(--mouse-y) / 40)) translateZ(+100px);
	}
	@media (max-width: 1500px) {
		width: 400px;
	}

	@media (max-width: 1200px) {
		width: 100%;
		max-width: 400px;
		margin-left: 40px;
	}

	@media (max-width: 850px) {
		margin-left: 120px;

		width: 110%;
	}
	@media (max-width: 700px) {
		margin-top: 50px;
		max-width: unset;
		margin-left: unset;

		position: relative;
		right: -15%;
		width: 100%;
	}
}
#how-container {
	background: black;
}
