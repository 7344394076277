$color_1: #ffffff;
$color_2: rgba(255, 255, 255, 0.7);
$color_3: var(--white);
$font-family_2: "Sora", sans-serif;

@import "./Variables.scss";
.singup-background {
	display: none;
}
.signup-box {
	position: relative;
	width: 35vw;
	text-align: center;
	color: $color_1;
	padding: 20px 15px 40px 15px;
	background: rgba(0, 0, 0, 0.4);
	backdrop-filter: blur(15px);
	border-radius: 25px;
	margin: auto;
	transition: width, opacity 0.2s;
	margin-top: 7rem;
	.inputContainer {
		width: 70%;
		height: 100%;
		margin: auto auto 3vh auto;
	}
	.passwordContainer {
		position: relative;
		.passwordInfo {
			position: absolute;
			top: 50%;
			left: 90%;
			transform: translate(-50%, -50%);
			cursor: pointer;
		}
	}
	.text-label {
		text-align: center;
		margin-top: 10px;
	}
	h1 {
		font-size: 32px;
	}
	hr {
		margin-bottom: 30px;
		border-bottom: 1px solid rgb(255, 255, 255);
		opacity: 0.7;
	}
	.buttons-singup {
		position: relative;
		top: 1rem;
		width: 45%;
		margin: auto;
		min-width: fit-content;
	}
	.btn-outline {
		width: 100%;
		position: relative;
		margin: 20px auto;
		display: block;
		white-space: nowrap;
	}
	.select-container {
		margin: 0 auto;
	}
	label {
		opacity: 0.5;
		color: $color_1;
		font-size: 13px;
		a {
			outline: none;
			text-decoration: underline;
			color: $color_1;
		}
	}
}
.login-box {
	position: relative;
	width: 35vw;
	text-align: center;
	color: $color_1;
	padding: 20px 15px 40px 15px;
	background: rgba(0, 0, 0, 0.4);
	backdrop-filter: blur(15px);
	border-radius: 25px;
	margin: auto;
	transition: width, opacity 0.2s;
	margin-top: 7rem;
	.inputContainer {
		width: 70%;
		height: 100%;
		margin: auto auto 7vh auto;
	}
	.text-label {
		text-align: center;
		margin-top: 10px;
	}
	h1 {
		font-size: 32px;
	}
	hr {
		margin-bottom: 30px;
		border-bottom: 1px solid rgb(255, 255, 255);
		opacity: 0.7;
	}
	.buttons-login {
		position: relative;
		top: 1rem;
		width: 45%;
		margin: auto;
		min-width: fit-content;
	}
	.btn-outline {
		width: 100%;
		position: relative;
		margin: 20px auto;
		display: block;
		white-space: nowrap;
	}
	.select-container {
		margin: 0 auto;
	}
	label {
		opacity: 0.5;
		color: $color_1;
		font-size: 13px;
		a {
			outline: none;
			text-decoration: underline;
			color: $color_1;
		}
	}
}
.signup {
	.inputContainer {
		.error {
			width: 200%;
			left: -50%;
		}
	}
	font-family: $font-family_2;
	position: relative;
	width: 100%;
	height: fit-content;
	display: flex;
	align-items: center;
	justify-items: center;
}
.passwordOverlay {
	position: absolute;
	opacity: 0;
	top: 100%;
	right: 0;
	z-index: -1;
	text-align: left;
	background: #121212;
	padding: 10px;
	border-radius: 10px;
	transition: opacity 0.2s ease-in;
	&::before {
		content: "";
		position: absolute;
		width: 10px;
		height: 10px;
		border: 7px solid transparent;
		border-bottom: 7px solid #121212;
		top: -7%;
		right: 3vw;
	}
}
.passwordInfo {
	&:hover {
		~ {
			.passwordOverlay {
				opacity: 1;
				z-index: 100;
			}
		}
	}
}
.reset-password {
	cursor: pointer;
	position: relative;
	width: 100%;
	display: block;
	text-align: right;
	margin-bottom: 3%;
	a {
		margin-right: 5%;
	}
}
#resetDialog {
	display: none;
	opacity: 0;
	z-index: 100;
	transition: all 0.2s;
	.back-icon {
		display: block;
		cursor: pointer;
		color: $color_2;
		transition: color 0.2s;
		&:hover {
			color: $color_3;
		}
	}
}
label {
	opacity: 0.5;
	color: $color_1;
	font-size: 13px;
}
.login {
	font-family: $font-family_2;
	position: relative;
	width: 100%;
	height: fit-content;
	display: flex;
	align-items: center;
	justify-items: center;
	.login-video {
		position: fixed;
		right: 0;
		bottom: 0;
		min-width: 100%;
		min-height: 100%;
	}
}
.signup-video {
	position: fixed;
	right: 0;
	bottom: 0;
	min-width: 100%;
	min-height: 100%;
}
#confirm-privacy-policies-text {
	font-family: $font-family_2;
	font-size: 13px;
}
#confirm-privacy-policies-checkbox {
	margin: 10px;
}
.privacy-policies-link {
	font-family: $font-family_2;
	font-weight: 300;
	outline: none;
	text-decoration: underline;
}
.signup-main {
	position: relative;
	width: 100%;
}
.login-main {
	position: relative;
	width: 100%;
}
@media screen and (max-width: 1400px) {
	.signup-box {
		.buttons-singup {
			width: 60%;
		}
	}
	.login-box {
		.buttons-login {
			width: 60%;
		}
	}
}
@media screen and (max-width: 1100px) {
	.signup-box {
		width: 70%;
		text-align: center;
		.buttons-singup {
			width: 50%;
		}
	}
	.login-box {
		width: 70%;
		text-align: center;
		.buttons-login {
			width: 50%;
		}
	}
	.passwordOverlay {
		&::before {
			right: 6vw;
		}
	}
}
@media screen and (min-width: 0px) and (max-width: 800px) {
	.signup-box {
		width: 100%;
		text-align: center;
		.buttons-singup {
			width: 60%;
		}
	}
	.login-box {
		width: 100%;
		text-align: center;
		.buttons-login {
			width: 60%;
		}
	}
	.sign-up-btn {
		width: 60%;
	}
	.passwordOverlay {
		&::before {
			right: 9vw;
		}
	}
}
@media screen and (min-width: 0px) and (max-width: 400px) {
	.sign-up-box {
		font-size: 13px;
	}
	.login-box {
		font-size: 13px;
	}
	.btn-outline {
		font-size: 13px;
	}
	.sign-up-btn {
		width: fit-content !important;
		min-width: 100%;
	}
}
@media screen and (min-width: 700px) {
	.login-box-alert-mobile {
		display: none;
	}
}
@media screen and (min-width: 0px) and (max-width: 699px) {
	.login-box-alert-mobile {
		position: relative;
		top: 7%;
		width: 100%;
	}
}
@media screen and (min-width: 321px) and (max-width: 480px) {
	.signup-main {
		position: relative;
		width: 100%;
	}
	.signup-box {
		position: relative;
		width: 100%;
	}
	.signup-boxLanding {
		margin: 2%;
		bottom: 3em;
	}
}
